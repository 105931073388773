import React, { useState } from "react";  // Added useState import
import { Helmet } from "react-helmet";
import { Img, Text, Heading, Button } from "../../components";
import Footer from "../../components/Footer";
import Header from "../../components/Header";
import MigrateRowThree from "./MigrateRowThree";
import MigrateStackstudentvis from "./MigrateStackstudentvis";
//import MigrateRowFive from "./MigrateRowFive";
//import MigrateRowourclinet from "./MigrateRowourclinet";

import Brand from "../../components/Brand";
import Home1Rowourclinet from "../Home1/Home1Rowourclinet";
import Home1RowSeven from "../Home1/Home1RowSeven";
import FooterContact from "../../components/FooterContact";

export default function MigratePage() {
  const [selectedCountry, setSelectedCountry] = useState('Australia');
  const [sliderState, setSliderState] = React.useState(0);
  const sliderRef = React.useRef(null);

  // Scroll to Top Function
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'  // This makes the scrolling behavior smooth
    });
  };

  // Content for each country
  const countryData = {
    Australia: {
      imgSrc: "images/img_rectangle_4383.png",
      heading: "Why Migrate to Australia?",
      description:   <>
      There are several reasons people visit Australia. It offers plenty of opportunities for all.
      Australia is an open and multicultural country that is a chosen land for immigrants in today’s world.<br /> <br />
      Its strong economy provides diverse job opportunities and career growth. The country’s multicultural society
      ensures a welcoming environment. Enjoy beautiful landscapes and a vibrant lifestyle year-round.
      Clear pathways to permanent residency make it a great place for long-term stability.
    </>
    },
    Canada: {
      imgSrc: "images/img_screen_4384.png",
      heading: "Why Migrate to Canada?",
      description: 
        <>
          Canada is the second largest country in the world, with three territories and ten provinces.
          It is a country where half of the land is filled with forests.
          With spectacular landscapes, wide-open spaces, and pleasant weather, Canada has everything to make your holiday memorable!<br /> <br />
          From exploring the bustling streets of Toronto to exploring the majestic Canadian Rockies, this country offers a variety of destinations.
          Canada's vibrant landscapes, undisturbed nature, and rich culture make it a sought-after holiday destination for adventure lovers.
        </>
    },
    UK: {
      imgSrc: "images/img_screen_4385.png",
      heading: "Why Migrate to the UK?",
      description: `Immerse yourself in a city rich with history and cultural diversity. Benefit from world-class education with renowned institutions and cutting-edge research facilities. Experience a thriving economy with numerous career opportunities across various sectors. Enjoy access to excellent healthcare services through the NHS. Discover vibrant neighborhoods, iconic landmarks, and a dynamic arts scene. Take advantage of London’s extensive public transportation system and global connections. Explore a city that embraces innovation, creativity, and personal growth.`,
    },
    USA: {
      imgSrc: "images/img_screen_4386.png",
      heading: "Why Migrate to the USA?",
      description:   <>
      Discover a land of opportunity with diverse career prospects and innovation.
      Benefit from world-class educational institutions and cutting-edge research facilities.
      Enjoy a high standard of living with access to excellent healthcare and a strong economy.<br /> <br />
      Experience vibrant cities and diverse landscapes, from bustling metropolises to stunning natural wonders.
      Join a culture known for its entrepreneurial spirit and dynamic lifestyle.
      Explore a country with a rich cultural tapestry and numerous recreational activities.
    </>
    },
  };

  return (
    <>
      <Helmet>
        <title>JP Edu Tech</title>
        <meta name="description" content="Web site created using create-react-app" />
      </Helmet>
      <style>
        {`
        @keyframes slideDown {
              0% {
                transform: translateY(-100%); /* Starts off-screen from the top */
                opacity: 0;
              }
              100% {
                transform: translateY(0); /* Ends in its original position */
                opacity: 1;
              }
            }

            .animate-slideDown {
              animation: slideDown 1.5s ease-in-out forwards; /* Adjust duration and timing function as needed */
            }

            @keyframes slideUp {
              0% {
                transform: translateY(100%); /* Starts off-screen from the bottom */
                opacity: 0;
              }
              100% {
                transform: translateY(0); /* Ends in its original position */
                opacity: 1;
              }
            }

            .animate-slideUp {
              animation: slideUp 1.5s ease-in-out forwards; /* Adjust duration and timing function as needed */
            }

            @keyframes zoomInOut {
            0% {
              transform: scale(0.3); /* Start zoomed out */
            }
            100% {
              transform: scale(1.1); /* Zoomed in */
            }
          }

          .animate-zoomInOut {
            animation: zoomInOut 3s ease-in-out forwards; /* No infinite, forwards to retain final state */
          }
        `}
      </style>
      {/*Header*/}
      <Header />
      <div className="banner_inner bg-[url(/public/images/img_group_1285.png)] bg-cover bg-no-repeat relative migrate_banner">
        <div className="inner_bg">
          <div className="text-center">
            <h1 className="font6 font-bold text-white-a700_01 animate-zoomInOut">Connecting Dreams with <br /> New Destinations.</h1>
            <a href="/enquirepage" className="d-inline-flex mt-8">
              <span className='submitBtn'>
                <span className='cursor-pointer text-capitalize'>Appointment</span>
              </span>
            </a>
          </div>
        </div>
      </div>

      <div className="contact_col w-100 black_img mb__70 mig_cols">
        <div className="container mw_1400">
        <div className="d-lg-flex count_btn my-lg-4 my-2">
          <button
            size="6xl"
            shape="square"
            className={`font15_5 py-3 mx-2 my-2 w-full px-[34px] font-bold tracking-[-0.46px] cursor-pointer transition duration-300 ${
              selectedCountry === 'Australia'
                ? 'bg-light_blue-900 text-white-a700_01'
                : 'bg-white-a700_01 text-blue_gray-800 hover:bg-light_blue-900 hover:text-white-a700_01'
            }`}
            onClick={() => setSelectedCountry('Australia')}
          >
            Australia
          </button>

          <button
            size="6xl"
            shape="square"
            className={`font15_5 py-3 mx-2 my-2 w-full px-[34px] font-bold tracking-[-0.46px] cursor-pointer transition duration-300 ${
              selectedCountry === 'Canada'
                ? 'bg-light_blue-900 text-white-a700_01'
                : 'bg-white-a700_01 text-blue_gray-800 hover:bg-light_blue-900 hover:text-white-a700_01'
            }`}
            onClick={() => setSelectedCountry('Canada')}
          >
            Canada
          </button>

          <button
            size="6xl"
            shape="square"
            className={`font15_5 py-3 mx-2 my-2 w-full px-[34px] font-bold tracking-[-0.46px] cursor-pointer transition duration-300 ${
              selectedCountry === 'UK'
                ? 'bg-light_blue-900 text-white-a700_01'
                : 'bg-white-a700_01 text-blue_gray-800 hover:bg-light_blue-900 hover:text-white-a700_01'
            }`}
            onClick={() => setSelectedCountry('UK')}
          >
            UK
          </button>

          <button
            size="6xl"
            shape="square"
            className={`font15_5 py-3 mx-2 my-2 w-full px-[34px] font-bold tracking-[-0.46px] cursor-pointer transition duration-300 ${
              selectedCountry === 'USA'
                ? 'bg-light_blue-900 text-white-a700_01'
                : 'bg-white-a700_01 text-blue_gray-800 hover:bg-light_blue-900 hover:text-white-a700_01'
            }`}
            onClick={() => setSelectedCountry('USA')}
          >
            USA
          </button>

          <a href="/enquirepage" className="w-full">
            <button
              size="6xl"
              shape="square"
              className="font15_5 py-3 mx-2 my-2 w-full px-[34px] font-bold tracking-[-0.46px] cursor-pointer transition duration-300 bg-white-a700_01 text-blue_gray-800"
            >
              View All
            </button>
          </a>
        </div>
        </div>
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-6">
              <div>
                <Img src={countryData[selectedCountry].imgSrc} alt={`Image of ${selectedCountry}`}
                    className="count_img"
                />
              </div>
            </div>
            <div className="col-lg-6">
              <div className="mob_center">
                <h6 className="font3 font-bold my-4 tracking-[-0.91px] text-light_blue-900">{countryData[selectedCountry].heading}</h6>
                <p className="font1_5 font-normal leading-[30px] tracking-[0.40px] text-white-a700_01">{countryData[selectedCountry].description}</p>
                <button shape="square" className='submitBtn my-lg-5 my-3'>
                  <span className='cursor-pointer text-capitalize'>Know More</span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <MigrateStackstudentvis />
      <MigrateRowThree />
      {/*Brand*/}
      <Brand />
      {/*Testimonial*/}
      <Home1Rowourclinet />
      {/*Contact Form*/}
      <Home1RowSeven />
      <div className="container relative w-100">
        <div className='absolute scroll-index'>
          <span className="scroll-top d-flex align-items-center justify-content-center bg-light_blue-900 cursor-pointer"
            onClick={scrollToTop}>
            <Img src="images/img_vector_white_a700_01_14x24.svg" alt="Vector Eleven" className="h-[14px]" />
          </span>
        </div>
      </div>
      <FooterContact />
      {/*Footer*/}
      <Footer />
    </>
  );
}

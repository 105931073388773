import React, { useState, Suspense } from "react";
import Slider from "react-slick";
import { Img, Text } from "../../components";

const data = [
  { userImage: "images/img_view_3d_compute.png", hoverImage: "images/hover_image_1.png"},
  { userImage: "images/img_digital_art_sty.png", hoverImage: "images/hover_image_2.png"},
  { userImage: "images/img_clean_minimalis.png", hoverImage: "images/hover_image_3.png"},
  { userImage: "images/img_video_files_con.png", hoverImage: "images/hover_image_4.png"},
];

const settings = {
  dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: 2, // Show 2 images on tablets and mobiles
  slidesToScroll: 1,
  swipeToSlide: true,
  responsive: [
    {
      breakpoint: 768, // Mobile breakpoint
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false, // Hide arrows on mobile for cleaner UI
      },
    },
  ],
};

export default function SoftwareRowbrandidentit() {
  const [hoverIndex, setHoverIndex] = useState(null);

  return (
    <>
      <div className="container">
        <div className="row flex-lg-row-reverse">
          <div className="col-lg-5">
            <div className="relative w-90per ms-auto">
              <Img src="images/img_quoteducational.png" className="" />
              <div className="bg-gradient4 w-100 h-50 absolute bottom-0"></div>
            </div>
          </div>
          <div className="col-lg-7 ps-0">
            <div className="relative">
              <h2 className="font11 absolute left-0 top__100 font-inter font-bold tracking-[3.64px] text-blue_gray-800_6b">01</h2>
              <div className="relative w-85per ms-auto">
                <h3 className="font2 font-bold leading-[150%] tracking-[-0.61px] text-light_blue-900 line_before">Software Designing</h3>
                <h4 className="font3 font-semibold text-white-a700_01 mb-3">Transforming Ideas into Exceptional Software</h4>
                <p className="font1_5 font-normal leading-[34px] tracking-[0.40px] text-white-a700_01">At JP Edu Tech, software designing is more than just creating functional applications—it’s about crafting tailored solutions that drive progress and enhance experiences in the education sector. Our design process blends creativity with technical expertise to build software that meets the unique needs of educational institutions, educators, and learners.</p>
              </div>
              <div className="relative mt-4 w-85per ms-auto">
                <h2 className="font2 font-nunitosans font-bold tracking-[-0.61px] text-white-a700_01">Graphic Design :</h2>
                <h5 className="font1_5 font-semibold text-light_blue-900 mt-2">Transforming Ideas into Visual Masterpieces</h5>
                <p className="font1_5 font-normal leading-[30px] tracking-[0.40px] text-white-a700_01 my-3">Graphic design is crucial for creating visually engaging and effective educational materials. At JP Edu Tech, our Graphic Design services focus on delivering high-quality visuals that capture attention and communicate your message effectively.</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="ml-80per">
          <div className="my-4">
            <h5 className="font1_5 font-semibold text-light_blue-900 mt-2">Our Graphic Design Services Include:</h5>

            {/* Static view for desktop */}
            <div className="d-none d-lg-flex my-4 justify-content-between">
              {data.map((d, index) => (
                <div
                  key={"listbrandidenti_desktop" + index}
                  onMouseEnter={() => setHoverIndex(index)}
                  onMouseLeave={() => setHoverIndex(null)}
                  className="relative flex flex-col items-center mx-2 sbs"
                >
                  <Img
                    src={hoverIndex === index ? d.hoverImage : d.userImage}
                    alt={d.userBrandIdentity}
                    className="h-[150px] transition-all duration-300 ease-in-out"
                  />
                  <Text
                    className={`mt-4 text-[16px] font-semibold text-light_blue-900 transition-opacity duration-300 ${
                      hoverIndex === index ? "opacity-0" : "opacity-100"
                    }`}
                  >
                    {d.userBrandIdentity}
                  </Text>
                </div>
              ))}
            </div>

            {/* Slider for tablet and mobile view */}
            <div className="d-lg-none">
              <Slider {...settings}>
                {data.map((d, index) => (
                  <div key={"listbrandidenti_mobile" + index}>
                    <div
                      onMouseEnter={() => setHoverIndex(index)}
                      onMouseLeave={() => setHoverIndex(null)}
                      className="relative flex flex-col items-center mx-2 sbs"
                    >
                      <Img
                        src={hoverIndex === index ? d.hoverImage : d.userImage}
                        alt={d.userBrandIdentity}
                        className="h-[150px] transition-all duration-300 ease-in-out"
                      />
                      <Text
                        className={`mt-4 text-[16px] font-semibold text-light_blue-900 transition-opacity duration-300 ${
                          hoverIndex === index ? "opacity-0" : "opacity-100"
                        }`}
                      >
                        {d.userBrandIdentity}
                      </Text>
                    </div>
                  </div>
                ))}
              </Slider>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

import { Img, Button, Text, Heading } from "../../components";
import React from "react";

export default function MigrateStackstudentvis() {
  return (
    <>
    <div className="why_migrate w-100 bg-[url(/public/images/img_group_998.png)] bg-contain bg_F8FEFD bg_bottom bg-no-repeat">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-lg-7">
            <div className="w-100">
              <h6 className="font3 my-4 font-bold tracking-[-0.91px] text-light_blue-900"> Why do People Migrate?</h6>
              <div className="w-100">
                <div className="d-flex align-items-start w-100 mt-lg-4 mt-2">
                  <Img src="images/img_arrow_left.svg" alt="Arrowleft" className="mt-1.5 h-[22px]" />
                  <p className="font15_5 font-normal leading-[30px] tracking-[0.48px] text-gray-800 ms-lg-4 ms-2">
                    <span className="font-semibold">Economic Opportunities:&nbsp;</span>
                    <span>Seeking better jobs, higher wages, or business prospects</span>
                  </p>
                </div>
                <div className="d-flex align-items-start w-100 mt-lg-4 mt-2">
                  <Img src="images/img_arrow_left.svg" alt="Arrowleft" className="mt-1.5 h-[22px]" />
                  <p className="font15_5 font-normal leading-[30px] tracking-[0.48px] text-gray-800 ms-lg-4 ms-2">
                    <span className="font-semibold">Education:&nbsp;</span>
                    <span>Pursuing advanced studies or specialized training</span>
                  </p>
                </div>
                <div className="d-flex align-items-start w-100 mt-lg-4 mt-2">
                  <Img src="images/img_arrow_left.svg" alt="Arrowleft" className="mt-1.5 h-[22px]" />
                  <p className="font15_5 font-normal leading-[30px] tracking-[0.48px] text-gray-800 ms-lg-4 ms-2">
                    <span className="font-semibold">Safety and Security:&nbsp;</span>
                    <span>Escaping conflict, political instability, or persecution</span>
                  </p>
                </div>
                <div className="d-flex align-items-start w-100 mt-lg-4 mt-2">
                  <Img src="images/img_arrow_left.svg" alt="Arrowleft" className="mt-1.5 h-[22px]" />
                  <p className="font15_5 font-normal leading-[30px] tracking-[0.48px] text-gray-800 ms-lg-4 ms-2">
                    <span className="font-semibold">Family Reunification:&nbsp;</span>
                    <span>Joining relatives who have already settled elsewhere.</span>
                  </p>
                </div>
                <div className="d-flex align-items-start w-100 mt-lg-4 mt-2">
                  <Img src="images/img_arrow_left.svg" alt="Arrowleft" className="mt-1.5 h-[22px]" />
                  <p className="font15_5 font-normal leading-[30px] tracking-[0.48px] text-gray-800 ms-lg-4 ms-2">
                    <span className="font-semibold">Environmental Factors:&nbsp;</span>
                    <span>Moving away from natural disasters or adverse climate conditions.</span>
                  </p>
                </div>
                <div className="d-flex align-items-start w-100 mt-lg-4 mt-2">
                  <Img src="images/img_arrow_left.svg" alt="Arrowleft" className="mt-1.5 h-[22px]" />
                  <p className="font15_5 font-normal leading-[30px] tracking-[0.48px] text-gray-800 ms-lg-4 ms-2">
                    <span className="font-semibold">Lifestyle Preferences:&nbsp;</span>
                    <span>Finding a better quality of life or new cultural experiences.</span>
                  </p>
                </div>
                <button shape="square" className='submitBtn my-lg-5 my-3'>
                  <span className='cursor-pointer text-capitalize'>Know More</span>
                </button>
              </div>
            </div>
          </div>
          <div className="col-lg-5">
            <div className="m-none">
              <Img src="images/img_rectangle_4381_598x594.png"
                alt="Image"
                className="rounded-[10px]"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    </>
  );
}

import { Text, Img, Heading } from "../../components";
import React from "react";

export default function AboutusRow() {
  return (
    <>
      <div className="w-100">
        <div className="container mw_1920">
          <div className="row">
            <div className="col-lg-6 ps-0 pal-0">
              <div className="bg-light_blue-900 mission_img">
                <Img src="images/img_ellipse_53.png" alt="Image" className="animate-zoomInOut w-85per mx-auto"/>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="pr-4 mt-14 mb-4 mp-0">
                <div className="d-flex">
                  <h3 className="font3 font-medium text-black-900_01">Our mission</h3>
                  <Img src="images/img_close_light_blue_900_8x84.svg" alt="Close" className="ms-8" />
                </div>
                <p className="font1_5 font-normal leading-[33px] tracking-[0.40px] text-black-900_02 py-lg-6 py-3">
                Our mission is simple: to empower our clients with the knowledge and support they need to achieve their
                immigration goals efficiently and effectively. We prioritize transparency, integrity, and personalized
                service, ensuring that each client receives the attention and care they deserve.
                </p>
                <div className="d-flex">
                  <h3 className="font3 font-medium text-black-900_01">Our Vision</h3>
                  <Img src="images/img_close_light_blue_900_8x84.svg" alt="Close" className="ms-8" />
                </div>
                <p className="font1_5 font-normal leading-[33px] tracking-[0.40px] text-black-900_02 py-lg-6 py-3">
                At JP Edu Tech, we aim to simplify global migration by offering expert, personalized visa and immigration
                solutions. We are dedicated to empowering individuals to achieve their international educational and
                professional goals with ease and confidence. Our commitment is to deliver exceptional service and support
                throughout your journey. Transform your aspirations into reality with JP Edu Tech.
                </p>
                <div className="d-flex">
                  <h3 className="font3 font-medium text-black-900_01">Satisfied Clients</h3>
                  <Img src="images/img_close_light_blue_900_8x84.svg" alt="Close" className="ms-8" />
                </div>
                <p className="font1_5 font-normal leading-[33px] tracking-[0.40px] text-black-900_02 py-lg-6 py-3">
                We take pride in the satisfaction of our clients. Over the years, we have served more than 10,000
                individuals and businesses, earning their trust and loyalty through our exceptional service and successful
                results. Our clients’ testimonials and referrals are a testament to our commitment to their immigration
                success.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

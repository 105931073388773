import { Helmet } from "react-helmet";
import { Img, Text, Heading, Button, TextArea, Input, Slider } from "../../components";
import Header from "../../components/Header";
import SoftwareColumn from "./SoftwareColumn";
import SoftwareColumnTwo from "./SoftwareColumnTwo";
import SoftwareRowThree from "./SoftwareRowThree";
import SoftwareRowbrandidentit from "./SoftwareRowbrandidentit";
import SoftwareRowportraitprof from "./SoftwareRowportraitprof";
import SoftwareRowviewOne from "./SoftwareRowviewOne";
import React from "react";

//import SoftwareRowSix from "./SoftwareRowSix";
//import SoftwareRowourclinet from "./SoftwareRowourclinet";

import Footer from "../../components/Footer";
import Home1Rowourclinet from "../Home1/Home1Rowourclinet";
import Home1RowSeven from "../Home1/Home1RowSeven";
import FooterContact from "../../components/FooterContact";

export default function SoftwarePage() {
  const [sliderState, setSliderState] = React.useState(0);
  const sliderRef = React.useRef(null);

  // Scroll to Top Function
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'  // This makes the scrolling behavior smooth
    });
  };
  return (
    <>
      <Helmet>
        <title>JP Edu Tech</title>
        <meta name="description" content="Web site created using create-react-app" />
      </Helmet>
      <style>
        {`
        @keyframes slideDown {
              0% {
                transform: translateY(-100%); /* Starts off-screen from the top */
                opacity: 0;
              }
              100% {
                transform: translateY(0); /* Ends in its original position */
                opacity: 1;
              }
            }

            .animate-slideDown {
              animation: slideDown 1.5s ease-in-out forwards; /* Adjust duration and timing function as needed */
            }

            @keyframes slideUp {
              0% {
                transform: translateY(100%); /* Starts off-screen from the top */
                opacity: 0;
              }
              100% {
                transform: translateY(0); /* Ends in its original position */
                opacity: 1;
              }
            }

            .animate-slideUp {
              animation: slideUp 1.5s ease-in-out forwards; /* Adjust duration and timing function as needed */
            }

            @keyframes leftToRight {
              0% {
                transform: translateX(-100%); /* Starts off-screen to the left */
                opacity: 0;
              }
              100% {
                transform: translateX(0); /* Ends in its original position */
                opacity: 1;
              }
            }

            .animate-leftToRight {
              animation: leftToRight 2s ease-in-out forwards; /* Adjust the duration as needed */
            }
               @keyframes RightToleft {
              0% {
                transform: translateX(100%); /* Starts off-screen to the left */
                opacity: 0;
              }
              100% {
                transform: translateX(0); /* Ends in its original position */
                opacity: 1;
              }
            }
             .animate-RightToleft{
             animation: RightToleft 2s ease-in-out backwards;
             } 
          @keyframes zoomInOut {
            0% {
              transform: scale(0.3); /* Normal size */
            }
            100% {
              transform: scale(1.1); /* Zoomed in */
            }
           
          }

          .animate-zoomInOut {
            animation: zoomInOut 3s ease-in-out forwards; /* No infinite, and forwards to keep final state */
          }
        `}
      </style>
      {/*Header*/}
      <Header />

      <SoftwareColumn />

      <div className="contact_col black_img top__20 about_cols soft_cols pb_00">
        <div className="container mx-1100">
          <div className="text-center">
            <div className="animate-zoomInOut pb-8">
              <h2 className="font4 font-bold text-light_blue-900">What we do</h2>
              <p className="font1_5 my-4 font-normal leading-[30px] tracking-[0.40px] text-gray-50_03 font0_75">
                <span>At&nbsp;</span>
                <span className="">
                  JP EDU TECH, our software consulting services are designed to provide comprehensive solutions that
                  drive business success. Our approach combines deep technical knowledge with strategic insight to
                  help you navigate complex technology challenges and achieve your goals. We are dedicated to
                  helping you navigate the complexities of technology and achieve your business objectives with
                  confidence and efficiency. Contact us today to discover how our software consulting services can
                  drive your success.
                </span>
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="bg_06090A">
        <SoftwareRowThree />
        <div className="soft_box_1">
          <SoftwareRowbrandidentit />
          <SoftwareColumnTwo />
        </div>

        <SoftwareRowviewOne />

        <div className="soft_box_3">
          <div className="container">
            <div className="row align-items-center flex-lg-row-reverse">
              <div className="col-lg-5">
                <div className="relative w-90per ms-auto">
                  <Img src="images/img_man_is_looking_770x782.png" className="" />
                  <div className="bg-gradient4 w-100 h-50 absolute bottom-0"></div>
                </div>
              </div>
              <div className="col-lg-7 ps-0">
                <div className="relative">
                  <h2 className="font11 absolute left-0 top__100 font-inter font-bold tracking-[3.64px] text-blue_gray-800_6b">03</h2>
                  <div className="relative w-85per ms-auto">
                    <h3 className="font2 font-bold leading-[150%] tracking-[-0.61px] text-light_blue-900 line_before">Software Testing</h3>
                    <h4 className="font3 font-semibold text-white-a700_01 mb-3">Ensure Software Excellence with Our Expertise</h4>
                    <p className="font1_5 font-normal leading-[34px] tracking-[0.40px] text-white-a700_01">Ensure software excellence with our expert testing services. We develop robust test strategies, design
                    effective test cases, and implement automation for efficiency. Our focus on performance, security, and
                    usability guarantees top-notch software quality. Let us be your partner in delivering flawless digital
                    products.</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <SoftwareRowportraitprof />

      {/*Testimonial*/}
      <Home1Rowourclinet />
      {/*Contact Form*/}
      <Home1RowSeven />
      <div className="container relative w-100">
        <div className='absolute scroll-index'>
          <span className="scroll-top d-flex align-items-center justify-content-center bg-light_blue-900 cursor-pointer"
            onClick={scrollToTop}>
            <Img src="images/img_vector_white_a700_01_14x24.svg" alt="Vector Eleven" className="h-[14px]" />
          </span>
        </div>
      </div>
      <FooterContact />
      {/*Footer*/}
      <Footer />
    </>
  );
}

import React, { useState } from "react";
import { Text, Img, TextArea, Input } from "../../components";
import emailjs from 'emailjs-com'; // Make sure to install EmailJS

// Custom Modal Component
function SuccessModal({ message, onClose }) {
  return (
    <div className="modal-overlay">
      <div className="modal-container">
        <h3>{message}</h3>
        <button onClick={onClose}>Okay</button>
      </div>
    </div>
  );
}

export default function EnquirepageRowOne() {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [mobileNumber, setMobileNumber] = useState("");
  const [preferredDestination, setPreferredDestination] = useState("");
  const [studyPlan, setStudyPlan] = useState("");
  const [comments, setComments] = useState("");
  const [showSuccessModal, setShowSuccessModal] = useState(false);

  // Form submit handler with EmailJS
  const handleFormSubmit = (e) => {
    e.preventDefault();

    const templateParams = {
      firstName,
      lastName,
      email,
      mobileNumber,
      preferredDestination,
      studyPlan,
      comments,
    };

    // Send email using EmailJS
    emailjs
      .send(
        "service_hij3bpr", // Replace with your EmailJS service ID
        "template_s3if2fj", // Replace with your EmailJS template ID
        templateParams,
        "yUT7dkyQWAZoWKCES" // Replace with your EmailJS user ID
      )
      .then(
        (response) => {
          console.log("SUCCESS!", response.status, response.text);
          setShowSuccessModal(true); // Show success modal on success

          // Reset form fields
          setFirstName("");
          setLastName("");
          setEmail("");
          setMobileNumber("");
          setPreferredDestination("");
          setStudyPlan("");
          setComments("");
        },
        (error) => {
          console.log("FAILED...", error);
          alert("Failed to send your message. Please try again later.");
        }
      );
  };

  return (
    <>
      <style>
        {`
          /* Modal Overlay */
          .modal-overlay {
            position: fixed;
            top: 0;
            left: 0;
            width: 100vw;
            height: 100vh;
            background: rgba(0, 0, 0, 0.5);
            display: flex;
            justify-content: center;
            align-items: center;
            z-index: 1000;
          }

          /* Modal Container */
          .modal-container {
            background: white;
            padding: 20px;
            border-radius: 10px;
            width: 80%;
            max-width: 400px;
            text-align: center;
          }

          .modal-container h3 {
            margin-bottom: 20px;
          }

          .modal-container button {
            padding: 10px 20px;
            background-color: #1b5c82;
            color: white;
            border: none;
            border-radius: 5px;
            cursor: pointer;
          }

          .modal-container button:hover {
            background-color: #0056b3;
          }
        `}
      </style>

      <h2 className="font2_5 font-semibold text-white-a700_01 pb-4">
        Fill the form for enquire / Appointment..
      </h2>
      <div className="form_design mt-4">
        <form onSubmit={handleFormSubmit}>
          {/* First Name and Last Name Inputs */}
          <div className="d-lg-flex">
            <Input
              size="lg"
              shape="square"
              type="text"
              name="firstName"
              placeholder="First Name"
              value={firstName}
              onChange={(e) => setFirstName(e.target.value)}
              style={{ border: "none" }}
              className="con_label w-100"
              required
            />
            <Input
              size="lg"
              shape="square"
              type="text"
              name="lastName"
              placeholder="Last Name"
              value={lastName}
              onChange={(e) => setLastName(e.target.value)}
              style={{ border: "none" }}
              className="con_label ms-lg-4 w-100"
              required
            />
          </div>

          {/* Email Input */}
          <Input
            size="lg"
            shape="square"
            type="email"
            name="email"
            placeholder="Email address"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            style={{ border: "none" }}
            className="con_label w-100"
            required
          />

          {/* Mobile Number Input */}
          <Input
            size="lg"
            shape="square"
            type="number"
            name="mobileNumber"
            placeholder="Mobile number"
            value={mobileNumber}
            onChange={(e) => setMobileNumber(e.target.value)}
            style={{ border: "none" }}
            className="con_label w-100"
            required
          />

          {/* Study Destination and Study Plan Inputs */}
          <Input
            size="lg"
            shape="square"
            name="preferredDestination"
            placeholder="Your preferred study destination"
            value={preferredDestination}
            onChange={(e) => setPreferredDestination(e.target.value)}
            style={{ border: "none" }}
            className="con_label w-100"
            required
          />
          <Input
            size="lg"
            shape="square"
            name="studyPlan"
            placeholder="When do you plan to study?"
            value={studyPlan}
            onChange={(e) => setStudyPlan(e.target.value)}
            style={{ border: "none" }}
            className="con_label w-100"
            required
          />

          {/* Comments Text Area */}
          <textArea
            size="lg"
            shape="square"
            name="comments"
            placeholder="  Comments..."
            value={comments}
            onChange={(e) => setComments(e.target.value)}
            style={{ border: "none" }}
            className="con_label w-100"
            required
          />
          <button type="submit" shape="square" className="submitBtn my-lg-4 my-2">
            <span className="cursor-pointer text-capitalize advice">Get Experts Advice</span>
          </button>
        </form>
      </div>

      {/* Talk to Our Visa Experts Section */}
      <h3 className="font1_5 font-semibold tracking-[0.40px] text-white-a700_01">
        Talk to Our Visa Experts
      </h3>
      <div className="mt-4 d-flex align-items-center">
        <Img src="/images/img_icon_white_a700_01.png" alt="Icon" className="w-40px" />
        <p className="font1_5 font-normal text-white-a700_01 ms-4">
          <span>+91 9704428144</span>
          <span className="ms-4">+91 8247510882</span>
        </p>
      </div>

      {/* Success Modal */}
      {showSuccessModal && (
        <SuccessModal
          message="Your message has been sent successfully!"
          onClose={() => setShowSuccessModal(false)}
        />
      )}
    </>
  );
}

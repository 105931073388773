import { Helmet } from "react-helmet";
import { Img, Heading, Text, Input } from "../../components";
import Header from "../../components/Header";
import EnquirepageRowOne from "./EnquirepageRowOne";
import React from "react";
import ProjectRoutes from 'Routes';

import Footer from "../../components/Footer";




export default function EnquirepagePage() {
  return (
    <>
      <Helmet>
        <title>JP Edu Tech</title>
        <meta name="description" content="Web site created using create-react-app" />
      </Helmet>
      {/*Header*/}
      <Header />

      <div className="banner_inner bg-[url(/public/images/img_group_149.png)] bg-cover bg-no-repeat relative contact_banner">
        <div className="inner_bg trans">
          <div className="text-center">
            <h1 className="font6 font-bold text-white-a700_01 animate-zoomInOut">Quick Enquire</h1>
          </div>
        </div>
      </div>

      <div className="contact_col black_img w-100 mb__70">
        <div className="container mx-1100">
          <EnquirepageRowOne />
        </div>
      </div>

      {/*Footer*/}
      <div className="foot_bot">
        <Footer />
      </div> 
    </>
  );
}

import { Img } from "../../components";
import React from "react";

export default function StudyabroadRowFour() {
  return (
    <>
      <style>
        {`
          /* Default styles for desktop */
          .video_para {
            font-size: 16px;
            line-height: 28px;
          }
          .video_play {
            width: 100px;
            height: 100px;
          }

          /* Mobile-specific styles */
          @media (max-width: 768px) {
            .video_para {
              font-size: 13px;
              line-height: 18px;
            }
            .video_play {
              width: 500px; /* Adjust video play icon size */
              height: 120px;
            }
            .plane_icons {
              width: 70px; /* Adjust plane icon size */
              height: 70px;
            }
            .rounded-img {
              max-width: 100%; /* Ensure images are responsive */
              height: auto;
            }
          }
        `}
      </style>

      <div className="bg-gray-50_01 desti_pad">
        <div className="container">
          <div className="row">
            {/* Left Column */}
            <div className="col-lg-6 m-none">
              <div className="relative w-90per mx-auto borderLef">
                <div className="relative">
                  <Img
                    src="images/rectangl_4293_shade.png"
                    alt="Image"
                    className="rounded-[10px] absolute w-100 rounded-img"
                  />
                  <Img
                    src="images/rectangl_4293.png"
                    alt="Image"
                    className="rounded-[10px] rounded-img"
                  />
                </div>
                <Img
                  src="images/img_rectangle_4294.png"
                  alt="Graduationcap"
                  className="absolute left__8 bottom-0 w-50 z-40"
                />
                <Img
                  src="images/plane_icon.png"
                  alt="Plane Icon"
                  className="absolute plane_icons z-50"
                />
              </div>
            </div>

            {/* Right Column */}
            <div className="col-lg-6">
              <div className="mob_choose">
                <div className="relative choose_bg">
                  <h3 className="color_424242 font-semibold font2_5 mb-2">
                    Choosing your own destination is a powerful way to shape your future and achieve your goals.
                  </h3>
                </div>
                <p className="font1_5 font-normal color_424242 leading-[30px] tracking-[0.40px] pb-3">
                  Choosing your own destination means selecting the best location to achieve your personal and professional goals.
                  Research various options to find the right fit for education, career opportunities, and lifestyle. 
                  Consider factors like quality of life, cost of living, and immigration requirements. 
                  Prepare for relocation by planning practical aspects and seeking guidance to ensure a smooth transition.
                </p>

                <div className="borer">
                  {[
                    {
                      title: "Define Your Objectives:",
                      text: "Identify what you want to achieve, whether it's career advancement, academic growth, or cultural exploration.",
                    },
                    {
                      title: "Research Opportunities:",
                      text: "Explore various locations to find the best fit for your goals, considering factors like education, job markets, and lifestyle.",
                    },
                    {
                      title: "Understand Immigration Requirements:",
                      text: "Check Visa and Immigration policies to ensure a smooth transition to your chosen destination.",
                    },
                  ].map((item, index) => (
                    <div key={index} className="d-flex align-items-start w-100 mt-2">
                      <Img
                        src="images/img_arrow_left.svg"
                        alt="Arrowleft"
                        className="mt-1.5 h-[20px] m-none"
                      />
                      <p className="font1_5 font-normal leading-[30px] tracking-[0.48px] text-gray-800 ms-lg-2">
                        <span className="font-semibold">{item.title}&nbsp;</span>
                        <span>{item.text}</span>
                      </p>
                    </div>
                  ))}
                </div>

                {/* Single Paragraph with Icon */}
                <div className="d-flex align-items-center mt-3">
                  <p className="font1 font-normal tracking-[0.48px] text-gray-800 pe-lg-4 video_para">
                    Embarking on a migration journey opens doors to new opportunities and experiences. 
                    Embrace the adventure, and let us support you in making a smooth and successful transition. 
                    Your new chapter awaits—take the first step toward a brighter future today!
                  </p>
                  <Img
                    src="images/video_play.png"
                    alt="Video Play Icon"
                    className="video_play"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

import { Helmet } from "react-helmet";
import { Text, Heading, Img, Button } from "../../components";
import Footer from "../../components/Footer";
import Header from "../../components/Header";
import UserProfile3 from "../../components/UserProfile3";
import VisaProfile from "../../components/VisaProfile";
import AboutusColumnTwo from "./AboutusColumnTwo";
import AboutusRow from "./AboutusRow";
import React, { Suspense } from "react";

//import AboutusRowTwo from "./AboutusRowTwo";
//import AboutusStackiconOne from "./AboutusStackiconOne";

import Brand from "../../components/Brand";
import Home1Rowourclinet from "../Home1/Home1Rowourclinet";
import Home1RowSeven from "../Home1/Home1RowSeven";
import FooterContact from "../../components/FooterContact";


const data1 = [
  { userImage: "images/img_graduation_1.png", visaText: "visa & Immigration" },
  { userImage: "images/img_businessman_1.png", visaText: "Software Consulting" },
  { userImage: "images/briefcase.png", visaText: "Business Consulting" },
  { userImage: "images/finance.png", visaText: "Financial Consulting" },
  { userImage: "images/criteria.png", visaText: "Career Counselling" },
  { userImage: "images/EN.png", visaText: "English Coaching" },
];

export default function AboutusPage() {

  const [sliderState, setSliderState] = React.useState(0);
  const sliderRef = React.useRef(null);

  // Scroll to Top Function
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'  // This makes the scrolling behavior smooth
    });
  };
  return (
    <>
      <Helmet>
        <title>JP Edu Tech</title>
        <meta name="description" content="Web site created using create-react-app" />
      </Helmet>
      <style>
        {`
        @keyframes slideDown {
              0% {
                transform: translateY(-100%); /* Starts off-screen from the top */
                opacity: 0;
              }
              100% {
                transform: translateY(0); /* Ends in its original position */
                opacity: 1;
              }
            }

            .animate-slideDown {
              animation: slideDown 1.5s ease-in-out forwards; /* Adjust duration and timing function as needed */
            }

            @keyframes slideUp {
              0% {
                transform: translateY(100%); /* Starts off-screen from the top */
                opacity: 0;
              }
              100% {
                transform: translateY(0); /* Ends in its original position */
                opacity: 1;
              }
            }

            .animate-slideUp {
              animation: slideUp 1.5s ease-in-out forwards; /* Adjust duration and timing function as needed */
            }

            @keyframes leftToRight {
              0% {
                transform: translateX(-100%); /* Starts off-screen to the left */
                opacity: 0;
              }
              100% {
                transform: translateX(0); /* Ends in its original position */
                opacity: 1;
              }
            }

            .animate-leftToRight {
              animation: leftToRight 2s ease-in-out forwards; /* Adjust the duration as needed */
            }
               @keyframes RightToleft {
              0% {
                transform: translateX(100%); /* Starts off-screen to the left */
                opacity: 0;
              }
              100% {
                transform: translateX(0); /* Ends in its original position */
                opacity: 1;
              }
            }
             .animate-RightToleft{
             animation: RightToleft 2s ease-in-out backwards;
             } 
          @keyframes zoomInOut {
            0% {
              transform: scale(0.3); /* Normal size */
            }
            100% {
              transform: scale(1.1); /* Zoomed in */
            }
           
          }

          .animate-zoomInOut {
            animation: zoomInOut 3s ease-in-out forwards; /* No infinite, and forwards to keep final state */
          }
        `}
      </style>
      {/*Header*/}
      <Header />
      <div className="banner_inner bg-[url('/public/images/img_rectangle_4187_1.png')] bg-cover bg-no-repeat relative about_banner">
        <div className="inner_bg">
          <div className="text-center">
            <h1 className="font6 font-bold text-white-a700_01 animate-zoomInOut">About JP Edu Tech</h1>
            <p className="font1_5 leading-7 text-white-a700_01 mt-4 animate-zoomInOut">At JP EDU TECH, we understand that navigating the complexities of visa and<br/>
            immigration processes can be daunting. That's why we're here—to simplify your<br/>
            journey and ensure your dreams of global mobility become a reality.</p>
            <a href="/enquirepage" className="d-inline-flex mt-8">
              <span className='submitBtn'>
                <span className='cursor-pointer text-capitalize'>Appointment</span>
              </span>
            </a>
          </div>
        </div>
      </div>

      <AboutusColumnTwo />

      <AboutusRow />

      <div className="resources bg-[url(/public/images/img_group_180.png)] bg-cover bg-no-repeat relative abt_res mig_res w-100">
        <Img src="images/img_rectangle_4485.png" alt="Image" className="absolute left_bar m-none" />
        <div className="container">
          <div className="row flex-lg-row-reverse">
            <div className="col-lg-5">
              <div className="d-lg-none text-left">
                <div className="d-flex mb-2">
                  <h3 className="font3 font-bold text-light_blue-900">
                    <span className="text-black-900_01">Services at&nbsp;</span>
                    <span className="text-black-900_01">Jp Edu Tech</span>
                  </h3>
                </div>
                <p className="font1_5 font-normal leading-[30px] tracking-[0.40px] text-black-900_02">At JP Edu Tech, we provide a comprehensive suite of services tailored to meet the diverse needs of
                  educational institutions and organizations. Our solutions are designed to leverage technology
                  effectively, enhance operational efficiency, and support educational goals. Explore our key services</p>
              </div>
              <div className="relative mw-200">
                <Img
                  src="images/img_group_132.png"
                  alt="Image Fifteen"
                  className="absolute left-0 top_img m-none"
                />
                <Img
                  src="images/img_portrait_profes_632x392.png"
                  alt="Imageteen"
                  className="animate-slideDown cen_img1"
                />
                <Img
                  src="images/img_rectangle_4250.png"
                  alt="Imageteen"
                  className="absolute bot_img left80 animate-slideUp"
                />
                <Img src="images/img_group_4.png" alt="Imageteen" className="absolute bottomPlane animate-RightToleft m-none"/>
              </div>
            </div>
            <div className="col-lg-7">
              <div className="abt_part">
                <div className="d-flex m-none mb-4">
                  <h3 className="font3 font-bold text-light_blue-900">
                    <span className="text-light_blue-900">Services at&nbsp;</span>
                    <span className="text-black-900_01">Jp Edu Tech</span>
                  </h3>
                </div>
                <p className="font1_5 font-normal leading-[30px] tracking-[0.40px] text-black-900_02 m-none">At JP Edu Tech, we provide a comprehensive suite of services tailored to meet the diverse needs of
                  educational institutions and organizations. Our solutions are designed to leverage technology
                  effectively, enhance operational efficiency, and support educational goals. Explore our key services</p>
                <div className="box_column mt-4">
                  <div className="row">
                    <Suspense fallback={<div>Loading feed...</div>}>
                      {data1.map((d, index) => (
                        <VisaProfile {...d} key={"aboutus" + index} />
                      ))}
                    </Suspense>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/*Brand*/}
      <Brand />
      {/*Testimonial*/}
      <Home1Rowourclinet />
      {/*Contact Form*/}
      <Home1RowSeven />
      <div className="container relative w-100">
        <div className='absolute scroll-index'>
          <span className="scroll-top d-flex align-items-center justify-content-center bg-light_blue-900 cursor-pointer"
            onClick={scrollToTop}>
            <Img src="images/img_vector_white_a700_01_14x24.svg" alt="Vector Eleven" className="h-[14px]" />
          </span>
        </div>
      </div>
      <FooterContact />
      {/*Footer*/}
      <Footer />
    </>
  );
}

import { Slider, Text, Heading } from "../../components";
import React from "react";
import { TabPanel, TabList, Tab, Tabs } from "react-tabs";

export default function ResourcesRowOne() {
  const [sliderState, setSliderState] = React.useState(0);
  const sliderRef = React.useRef(null);

  const goToPreviousSlide = () => {
    if (sliderRef.current) {
      sliderRef.current.slidePrev(); // Move to the previous slide
    }
  };

  const goToNextSlide = () => {
    if (sliderRef.current) {
      sliderRef.current.slideNext(); // Move to the next slide
    }
  };

  return (
    <div className="seminar_video bg-[url(/public/images/img_group_998.png)] bg-contain bg_F8FEFD bg_bottom bg-no-repeat w-100">
      <div className="container">
        <h3 className="font3 font-bold text-gray-700_01 py-4">Seminor videos</h3>

        <Tabs
          className="mt-8"
          selectedTabClassName="!text-white-a700_01 bg-light_blue-900 shadow-3xl"
          selectedTabPanelClassName="!relative tab-panel--selected"
        >
          <TabList className="flex flex-wrap">
            <Tab className="min_wi220 px-8 py-tb2 mx-2 font1_5 font-bold tracking-[-0.38px] text-light_blue-900 shadow-md hover:shadow-lg">
              All
            </Tab>
            <Tab className="min_wi220 px-8 py-tb2 mx-2 font1_5 font-bold tracking-[-0.38px] text-light_blue-900 shadow-md hover:shadow-lg">
              Up Coming Event
            </Tab>
            <Tab className="min_wi220 px-8 py-tb2 mx-2 font1_5 font-bold tracking-[-0.38px] text-light_blue-900 shadow-md hover:shadow-lg">
              Past Events
            </Tab>
          </TabList>

          <div className="relative h-[624px] content-center self-stretch md:h-auto">
            {[...Array(3)].map((_, index) => (
              <TabPanel key={`tab-panel${index}`} className="absolute items-center">
                <div className="w-100">
                  <div className="flex flex-col items-center gap-14 md:ml-0 sm:gap-7">
                    <div className="mx-auto flex w-full gap-5 self-stretch md:mx-0 md:flex-col z-10">
                      <Slider
                        autoPlay
                        autoPlayInterval={2000}
                        responsive={{
                          0: { items: 1 },
                          551: { items: 1 },
                          1051: { items: 2 },
                          1441: { items: 3 },
                        }}
                        disableDotsControls
                        activeIndex={sliderState}
                        onSlideChanged={(e) => setSliderState(e?.item)}
                        ref={sliderRef}
                        items={[
                          <div className="px-2.5" key="image1">
                            <div className="relative h-[454px] content-center lg:h-auto md:h-auto">
                              <img
                                src="images/img_rectangle_4253.png"
                                alt="First Image"
                                className="mx-auto h-[454px] w-full flex-1 object-cover"
                              />
                            </div>
                          </div>,
                          <div className="px-2.5" key="image2">
                            <div className="relative h-[454px] content-center lg:h-auto md:h-auto">
                              <img
                                src="images/img_rectangle_4254_2.png"
                                alt="Second Image"
                                className="mx-auto h-[454px] w-full flex-1 object-cover"
                              />
                            </div>
                          </div>,
                          <div className="px-2.5" key="image3">
                            <div className="relative h-[454px] content-center lg:h-auto md:h-auto">
                              <img
                                src="images/img_rectangle_4255_2.png"
                                alt="Third Image"
                                className="mx-auto h-[454px] w-full flex-1 object-cover"
                              />
                            </div>
                          </div>,
                        ]}
                      />
                    </div>

                    {/* Navigation Buttons */}
                    <div className="flex justify-center gap-4 w-full px-4 mt-4">
                      {/* Previous Slide Button */}
                      <button
                        onClick={goToPreviousSlide}
                        className="bg-light_blue-900 w-12 h-12 rounded-full flex items-center justify-center shadow-md focus:outline-none hover:bg-light_blue-900_cc"
                      >
                        <span className="text-white text-xl">{'<'}</span> {/* Left Arrow */}
                      </button>

                      {/* Next Slide Button */}
                      <button
                        onClick={goToNextSlide}
                        className="bg-light_blue-900 w-12 h-12 rounded-full flex items-center justify-center shadow-md focus:outline-none hover:bg-light_blue-900_cc"
                      >
                        <span className="text-white text-xl">{'>'}</span> {/* Right Arrow */}
                      </button>
                    </div>
                  </div>
                </div>
              </TabPanel>
            ))}
          </div>
        </Tabs>
      </div>
    </div>
  );
}

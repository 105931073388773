import { Text, Heading, Img } from "../../components";
import React from "react";

export default function SoftwareRowviewOne() {
  return (
    <>
    <div className="soft_box_2">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-lg-5">
            <div className="relative w-100 me-auto">
              <Img src="images/img_software_developers_office_192x488_1.png" className="" />
              <div className="bg-gradient4 w-100 h-50 absolute bottom-0"></div>
            </div>
          </div>
          <div className="col-lg-7 ps-0">
            <div className="relative">
              <h2 className="font11 absolute left-0 top__100 font-inter font-bold tracking-[3.64px] text-blue_gray-800_6b">02</h2>
              <div className="relative w-85per ms-auto">
                <h3 className="font2 font-bold leading-[150%] tracking-[-0.61px] text-light_blue-900 line_before">Software Development</h3>
                <h4 className="font3 font-semibold text-white-a700_01 mb-3">Unlock Your Business Potential with Expert Guidance</h4>
                <p className="font1_5 font-normal leading-[34px] tracking-[0.40px] text-white-a700_01">Elevate your technology initiatives with JP Edu Tech&#39;s expert software development consulting. We
                  provide strategic guidance through every stage of your project, from initial planning and requirements
                  analysis to technology selection and implementation. Our team ensures your software solutions are aligned
                  with your educational goals and institutional needs. By optimizing development processes and managing
                  project risks, we help you achieve efficient and successful outcomes. Partner with us for tailored advice
                  and innovative solutions that drive educational excellence and operational efficiency. Let’s turn your
                  technology vision into reality.</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    </>
  );
}
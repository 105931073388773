import { Text, Heading, Img } from "../../components";
import StudentVisaProfile from "../../components/StudentVisaProfile";
import React, { Suspense } from "react";

const data = [
  { studentImage: "images/img_graduation_1.png", studentVisaText: "Student Visa" },
  { studentImage: "images/img_businessman_1.png", studentVisaText: "Employment Visa" },
  { studentImage: "images/img_briefcase_1.png", studentVisaText: "Business Visa" },
  { studentImage: "images/img_travel_1.png", studentVisaText: "Tourist Visa" },
  { studentImage: "images/img_project_1.png", studentVisaText: "Application process" },
  { studentImage: "images/img_criteria_1.png", studentVisaText: "Check Your Eligibility " },
  { studentImage: "images/img_flight_ticket_1.png", studentVisaText: "Flight Bookings" },
  { studentImage: "images/img_landing_page_1.png", studentVisaText: "Post Landing Services" },
];

export default function MigrateRowThree() {
  return (
    <>
    <div className="resources bg-[url(/public/images/img_group_180.png)] bg-cover bg-no-repeat relative abt_res w-100">
      <div className="container">
        <div className="row">
          <div className="col-lg-5">
            <div className="d-lg-none text-left">
              <div className="d-flex mb-4">
                <h3 className="font3 font-bold text-light_blue-900">
                  <span className="text-black-900_01">Visa Types and Eligibility Assessment and&nbsp;</span>
                  <span className="text-light_blue-900">Other Assistance</span>
                </h3>
              </div>
              <p className="font1_5 font-normal leading-[30px] tracking-[0.40px] text-black-900_02">Choosing the ideal destination for immigration is a pivotal
                decision that can shape the trajectory of your life.</p>
            </div>
            <div className="relative mw-200">
              <Img
                src="images/img_group_43.png"
                alt="Image Fifteen"
                className="absolute right__20 top_img m-none"
              />
              <Img
                src="images/img_rectangle_4384.png"
                alt="Imageteen"
                className="animate-slideDown cen_img"
              />
              <Img
                src="images/img_rectangle_4250.png"
                alt="Imageteen"
                className="absolute bot_img animate-slideUp"
              />
              <Img src="images/img_group_4.png" alt="Imageteen" className="absolute bottomPlane bot__110 animate-RightToleft m-none"/>
            </div>
          </div>
          <div className="col-lg-7">
            <div className="mig_part">
              <div className="d-lg-flex d-none mb-2">
                <h3 className="font3 font-bold text-light_blue-900">
                  <span className="text-black-900_01">Visa Types and Eligibility Assessment and&nbsp;</span>
                  <span className="text-light_blue-900">Other Assistance</span>
                </h3>
              </div>
              <p className="font1_5 font-normal leading-[30px] tracking-[0.40px] text-black-900_02 w-75 d-none">Choosing the ideal destination for immigration is a pivotal
                decision that can shape the trajectory of your life.</p>
              <div className="box_column mt-4">
                <div className="row">
                  <Suspense fallback={<div>Loading feed...</div>}>
                    {data.map((d, index) => (
                      <StudentVisaProfile {...d} key={"migrate" + index} />
                    ))}
                  </Suspense>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    </>
  );
}

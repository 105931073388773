import { Heading, Button, RatingBar, Img, Text, Slider } from "../../components";
import React from "react";

export default function Home1Rowourclinet() {
  const [sliderState, setSliderState] = React.useState(0);
  const sliderRef = React.useRef(null);

  return (
    <>
    <div className="testimonial w-100 bg-[url(/public/images/img_group_2371.png)] bg-cover bg-no-repeat">
      <div className="container">
        <div className="text-center">
          <p className="font5 font-satisfy font-normal text-white-a700_01">Our Client</p> 
          <h1 className="font10 font-extrabold uppercase text-white-a700_01 test_title">Testimonial</h1>     
        </div>
      </div>
      <div className="container mx-1200 relative">
        <div className="absolute left-0 top-0 pos-rels">
          <Img src="images/img_vector_32.svg" alt="Vectorthirtytwo" className="relative left_tele" />
          <Img src="images/img_television_white_a700_01.svg" alt="Television" className="absolute left_comma" />
        </div>
        <div className="px-4 pt-25r">
          <div className="rounded-[48px] bg-white-a700_01">
            <Slider
              autoPlay
              autoPlayInterval={2000}
              responsive={{ 0: { items: 1 }, 551: { items: 1 }, 1051: { items: 1 }, 1441: { items: 1 } }}
              disableDotsControls
              activeIndex={sliderState}
              onSlideChanged={(e) => {
                setSliderState(e?.item);
              }}
              ref={sliderRef}
              items={[
                {
                  name: "Lavanya",
                  description: "I was overwhelmed by the visa application process until I found JP EDU TECH. Their expert guidance and support made the entire journey smooth. I highly recommend their services for anyone looking to Study/Work/Migrate Abroad.",
                  rating: 5,
                  imgSrc: "images/img_group_898.svg"
                },
                {
                  name: "Karthik K",
                  description: "The software developed by JP EDU TECH has significantly improved our efficiency and productivity. Their team is highly skilled and responsive to our requirements.",
                  rating: 5,
                  imgSrc: "images/img_group_898.svg"
                },
                {
                  name: "Dheeraj",
                  description: "The team at JP EDU TECH is incredibly knowledgeable and efficient. They helped me gather all the necessary documents and ensured a smooth visa application process. I'm so grateful for their assistance.",
                  rating: 5,
                  imgSrc: "images/img_group_898.svg"
                }
              ].map((item, index) => (
              <React.Fragment key={index}>
                <div className="relative h-[390px]">
                  <div className="absolute bottom-0 left-0 right-0 top-0 m-auto flex h-max flex-1">
                    <Img
                      src={item.imgSrc}
                      alt="Image"
                      className="h-[388px] w-[98%] object-contain"
                    />
                  </div>
                  <div className="absolute bottom-0 left-0 right-0 top-0 m-auto flex h-max flex-1 flex-col items-center gap-5 px-14 md:px-5 sm:px-4">
                    <Text
                      as="p"
                      className="self-stretch text-center text-[20px] font-normal leading-[30px] tracking-[0.40px] text-black-900_02 lg:text-[17px]"
                    >
                      {item.description}
                    </Text>
                    <div className="ml-1.5 mr-[18px] flex w-[88%] items-start justify-between gap-5 lg:w-full md:mx-0 md:w-full">
                      <Heading
                        size="headingxl"
                        as="h3"
                        className="self-center text-[24px] font-bold tracking-[-0.46px] text-light_blue-900 lg:text-[20px]"
                      >
                        {item.name}
                      </Heading>
                      <RatingBar value={item.rating} isEditable={true} size={16} starCount={5} className="flex gap-2.5" />
                    </div>
                  </div>
                </div>
              </React.Fragment>
              ))}
            />
          </div>
        </div>
        <div className="absolute right-0 top-0 pos-rels">
          <Img src="images/img_vector_31.svg" alt="Vectorthirtyone" className="relative right_tele" />
          <Img src="images/img_television.svg" alt="Television" className="absolute right_comma" />
        </div>
        <div className="d-flex gap-[18px] justify-content-center mt-20 arr_btn">
          <Button
            color="white_A700_01"
            size="sm"
            shape="round"
            onClick={() => {
              sliderRef?.current?.slidePrev();
            }}
            className="w-[34px] rounded-full px-1.5 rotate-180"
          >
            <Img src="images/img_group_2396.svg" />
          </Button>
          <Button
            color="white_A700_01"
            size="sm"
            shape="round"
            onClick={() => {
              sliderRef?.current?.slideNext();
            }}
            className="w-[34px] rounded-full px-1.5 rotate-180"
          >
            <Img src="images/img_group_2397.svg" />
          </Button>
        </div>
      </div>
    </div>
    </>
  );
}

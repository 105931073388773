import { Helmet } from "react-helmet";
import { Img, TextArea, Input } from "../../components";
import Header from "../../components/Header";
import UserProfile6 from "../../components/UserProfile6";
import ContactusColumn from "./ContactusColumn";
import Brand from "../../components/Brand";
import Footer from "../../components/Footer";
import React, { useState, Suspense } from "react";
import emailjs from "emailjs-com";

function SuccessModal({ message, onClose }) {
  return (
    <div className="modal-overlay">
      <div className="modal-container">
        <h3>{message}</h3>
        <button onClick={onClose}>Okay</button>
      </div>
    </div>
  );
}

const data = [
  { userImage: "images/img_rectangle_4253.png" },
  { userImage: "images/img_rectangle_4254.png" },
  { userImage: "images/img_rectangle_4255.png" },
];

export default function ContactUsPage() {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [mobileNumber, setMobileNumber] = useState("");
  const [comments, setComments] = useState("");
  const [showSuccessModal, setShowSuccessModal] = useState(false);

  // Predefined receiver email
  const receiverEmail = "jpetcsglobal@gmail.com";

  // Function to handle form submission and send email
  const handleFormSubmit = (e) => {
    e.preventDefault();

    const templateParams = {
      firstName,
      lastName,
      email,
      mobileNumber,
      comments,
      receiver_email: receiverEmail, // Send to the predefined receiver's email
    };

    // EmailJS configuration and sending email
    emailjs
      .send(
        "service_hij3bpr", // Replace with your EmailJS service ID
        "template_s3if2fj", // Replace with your EmailJS template ID
        templateParams,
        "yUT7dkyQWAZoWKCES" // Replace with your EmailJS user ID
      )
      .then(
        (response) => {
          console.log("SUCCESS!", response.status, response.text);
          setShowSuccessModal(true);
          // Reset form fields
          setFirstName("");
          setLastName("");
          setEmail("");
          setMobileNumber("");
          setComments("");
        },
        (error) => {
          console.log("FAILED...", error);
          alert("Failed to send the message. Please try again.");
        }
      );
  };

  return (
    <>
      <Helmet>
        <title>JP Edu Tech</title>
        <meta name="description" content="Web site created using create-react-app" />
      </Helmet>
      <style>
        {`
        @keyframes slideDown {
              0% {
                transform: translateY(-100%); /* Starts off-screen from the top */
                opacity: 0;
              }
              100% {
                transform: translateY(0); /* Ends in its original position */
                opacity: 1;
              }
            }

            .animate-slideDown {
              animation: slideDown 1.5s ease-in-out forwards; /* Adjust duration and timing function as needed */
            }

            @keyframes slideUp {
              0% {
                transform: translateY(100%); /* Starts off-screen from the top */
                opacity: 0;
              }
              100% {
                transform: translateY(0); /* Ends in its original position */
                opacity: 1;
              }
            }

            .animate-slideUp {
              animation: slideUp 1.5s ease-in-out forwards; /* Adjust duration and timing function as needed */
            }

            @keyframes leftToRight {
              0% {
                transform: translateX(-100%); /* Starts off-screen to the left */
                opacity: 0;
              }
              100% {
                transform: translateX(0); /* Ends in its original position */
                opacity: 1;
              }
            }

            .animate-leftToRight {
              animation: leftToRight 2s ease-in-out forwards; /* Adjust the duration as needed */
            }
               @keyframes RightToleft {
              0% {
                transform: translateX(100%); /* Starts off-screen to the left */
                opacity: 0;
              }
              100% {
                transform: translateX(0); /* Ends in its original position */
                opacity: 1;
              }
            }
             .animate-RightToleft{
             animation: RightToleft 2s ease-in-out backwards;
             } 
          @keyframes zoomInOut {
            0% {
              transform: scale(0.3); /* Normal size */
            }
            100% {
              transform: scale(1.1); /* Zoomed in */
            }
           
          }

          .animate-zoomInOut {
            animation: zoomInOut 3s ease-in-out forwards; /* No infinite, and forwards to keep final state */
          }
        @keyframes zoomInOut1 {
          0% {
            transform: scale(0.1); /* Smaller starting size */
          }
          100% {
            transform: scale(1.0); /* Reduced zoom-in size */
          }
        }

        .animate-zoomInOut1 {
          animation: zoomInOut1 3s ease-in-out forwards; /* Adjusted to zoomInOut1 */
        }

        /* Modal Overlay */
        .modal-overlay {
          position: fixed;
          top: 0;
          left: 0;
          width: 100vw;
          height: 100vh;
          background: rgba(0, 0, 0, 0.5);
          display: flex;
          justify-content: center;
          align-items: center;
          z-index: 1000;
        }

        /* Modal Container */
        .modal-container {
          background: white;
          padding: 20px;
          border-radius: 10px;
          width: 80%;
          max-width: 400px;
          text-align: center;
        }

        .modal-container h3 {
          margin-bottom: 20px;
        }

        .modal-container button {
          padding: 10px 20px;
          background-color: #1b5c82;
          color: white;
          border: none;
          border-radius: 5px;
          cursor: pointer;
        }

        .modal-container button:hover {
          background-color: #0056b3;
        }


        `}
      </style>


      {/*Header*/}
      <Header />

      <div className="banner_inner bg-[url(/public/images/img_group_233.png)] bg-cover bg-no-repeat relative contact_banner">
        <div className="inner_bg trans">
          <div className="text-center">
            <h1 className="font6 font-bold text-white-a700_01 animate-zoomInOut">How Can we Help You?</h1>
          </div>
        </div>
      </div>

      <ContactusColumn />

      <div className="w-100 relative">
        <div className="container mw_1920">
          <div className="row flex-lg-row-reverse">
            <div className="col-lg-4 relative">
              <Img src="images/img_rectangle_4252.png" alt="Image" className="absolute right-0 top-0 con_img m-none" />
            </div>
            <div className="col-lg-8">
              <div className="form_part">
                <div className="d-flex">
                  <h3 className="font2_5 font-semibold text-light_blue-900">
                    <span className="text-light_blue-900 m-none">Send a&nbsp;</span>
                    <span className="text-gray-800 m-none">Message</span>
                    <span className="text-gray-800 d-lg-none">Career</span>
                  </h3>
                  <Img src="images/img_close_light_blue_900_8x84.svg" alt="Close" className="ms-8" />
                </div>
                {/* Form Section */}
                <form className="form_design mt-4" onSubmit={handleFormSubmit}>
                  <div className="d-lg-flex">
                    <Input
                      size="xl"
                      shape="square"
                      type="text"
                      name="firstName"
                      placeholder="First name"
                      value={firstName}
                      onChange={(e) => setFirstName(e.target.value)}
                      style={{ border: "none" }}
                      className="con_label w-100"
                      required
                    />
                    <Input
                      size="xl"
                      shape="square"
                      type="text"
                      name="lastName"
                      placeholder="Last name"
                      value={lastName}
                      onChange={(e) => setLastName(e.target.value)}
                      style={{ border: "none" }}
                      className="con_label ms-lg-4 w-100"
                      required
                    />
                  </div>
                  <Input
                    size="xl"
                    shape="square"
                    type="email"
                    name="email"
                    placeholder="Email address"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    style={{ border: "none" }}
                    className="con_label w-100"
                    required
                  />
                  <Input
                    size="xl"
                    shape="square"
                    type="number"
                    name="mobileNumber"
                    placeholder="Mobile number"
                    value={mobileNumber}
                    onChange={(e) => setMobileNumber(e.target.value)}
                    style={{ border: "none" }}
                    className="con_label w-100"
                    required
                  />
                  <textArea
                    size="xl"
                    shape="square"
                    name="comments"
                    placeholder=" Comments..."
                    value={comments}
                    onChange={(e) => setComments(e.target.value)}
                    style={{ border: '1px solid #ccc', height: '100px', width: '100%', padding: '10px' }}
                    className="con_label w-100"
                    required
                  />
                  <button type="submit" className="submitBtn my-lg-4 my-2">
                    <span className="cursor-pointer text-uppercase">Send</span>
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="container mw_1300 mt-8 con_bot_img">
        <div className="d-lg-flex animate-RightToleft justify-content-between padd0">
          <Suspense fallback={<div>Loading feed...</div>}>
            {data.map((d, index) => (
              <UserProfile6 {...d} key={"listvectorone" + index} />
            ))}
          </Suspense>
        </div>
      </div>

      {/* Brand and Footer Components */}
      <Brand />
      <div className="foot_bot">
        <Footer />
      </div>

      {showSuccessModal && (
        <SuccessModal
          message="Your message has been sent successfully!"
          onClose={() => setShowSuccessModal(false)}
        />
      )}

    </>
  );
}
import React from "react";
import ProjectRoutes from "./Routes";
import { BrowserRouter as Router } from "react-router-dom"; // Use BrowserRouter instead of HashRouter

function App() {
  return (
    <Router>
      <ProjectRoutes />
    </Router>
  );
}

export default App;

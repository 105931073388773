import { Button, Img } from "./..";
import React, { Suspense } from "react";

const data = [
  { png: "images/img_2_1_png.png" },
  { png: "images/img_1_1_png.png" },
  { png: "images/img_2_1_png.png" },
  { png: "images/img_4_1_png.png" },
  { png: "images/img_1_1_png.png" },
  { png: "images/img_4_1_png.png" },
];

export default function Brand() {
  return (
    <>
    <div className='google_icon w-100'>
      <div className='container'> 
        <div className='swip_img'>
          <Suspense fallback={<div>Loading feed...</div>}>
            {data.map((d, index) => (
              <Img
                key={"listpng" + index}
                src={d.png}
                alt="Png"
                className=""
              />
            ))}
          </Suspense>
        </div>
      </div>
    </div>
    </>
  );
}

import { Text, Heading, Img } from "./..";
import React from "react";

export default function FooterContact() {
  return (
    <>
      <div className="contact_detail w-100 text-white">
        <div className="container">
          <div className="contact_detail_bg fs-6 line-height26 py-8 px-12">
            <div className="row">
              <div className="col-lg-4">
                <div className="d-flex justify-content-center mov_lefts">
                  <span className="con-box d-flex align-items-center justify-content-center bg-white me-4">
                    <Img src="images/call_icon.png" alt="image" />
                  </span>
                  <div>
                    <span className="d-block font-bold hea_tits m-none">Call Us Anytime</span>
                    <span className="d-block in-block pr-2">+91 9704428144</span>
                    <span className="d-block in-block">+91 8247510882</span>
                  </div>
                </div>
              </div>
              <div className="col-lg-4">
                <div className="d-flex justify-content-center mov_lefts">
                  <span className="con-box d-flex align-items-center justify-content-center bg-white me-4">
                    <Img src="images/mail_icon.png" alt="image" />
                  </span>
                  <div>
                    <span className="d-block font-bold hea_tits m-none">Send Mail</span>
                    <span className="d-block">jpedutechcs@gmail.com  </span>
                  </div>
                </div>
              </div>
              <div className="col-lg-4">
                <div className="d-flex justify-content-center mov_lefts">
                  <span className="con-box d-flex align-items-center justify-content-center bg-white me-4">
                    <Img src="images/location_icon.png" alt="image" /> 
                  </span>
                  <div>
                    <span className="d-block font-bold hea_tits m-none">Our Branche's</span>
                    <span className="d-block">Kurnool, Hyderabad,<br />Guntur, Melbourne (Australia)</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

import { Img, Button, Text, Heading } from "../../components";
import React from "react";

export default function Home1RowFive() {
  return (
    <>
    <div className="resources">
      <div className="container">
        <div className="row">
          <div className="col-lg-6">
            <h1 className='font-playfairdisplay font5 font-medium color_3A3F50 d-lg-none'>Resources</h1>
            <div className="relative mw-200">
              <Img
                src="images/img_group_18.png"
                alt="Image Fifteen"
                className="absolute left-0 top_img"
              />
              <Img
                src="images/img_rectangle_4249.png"
                alt="Imageteen"
                className="animate-slideDown cen_img"
              />
              <Img
                src="images/img_rectangle_4250.png"
                alt="Imageteen"
                className="absolute bot_img animate-slideUp"
              />
            </div>
          </div>
          <div className="col-lg-6">
            <div className="animate-RightToleft pt-20 pt2r">
              <Img src='images/img_close_light_blue_900.svg' alt='' className="m-none" />
              <div className='pt-8 m-none'>
                <h1 className='font-playfairdisplay font5 font-medium color_3A3F50'>Resources</h1>
              </div>
              <p className='py-8 bannerP font-semibold'>Our Resources section is designed to provide you with the tools and knowledge you need for a successful
            study abroad or immigration experience. Explore our curated collection of articles, blogs, and helpful links
            to stay informed and make well-informed decisions.</p>
              <button shape="square" className='submitBtn'>
                <span className='cursor-pointer text-capitalize'>Know More</span>
              </button>
            </div>
            <div className="relative animate-RightToleft">
              <Img src="images/img_group_13.png" alt="Imageteen" className="absolute bottomLines"/>
            </div>
          </div>
        </div>
      </div>
    </div>
    </>
  );
}

import React, { useState } from "react";
import { Helmet } from "react-helmet";
import emailjs from "emailjs-com"; // Import EmailJS
import { Img, Heading, Button, Text, Input, TextArea } from "../../components";
import Header from "../../components/Header";
import EnglishcoachingColumnFive from "./EnglishcoachingColumnFive";
import { TabList, Tab, Tabs } from "react-tabs";
import Footer from "../../components/Footer";
import Home1Rowourclinet from "../Home1/Home1Rowourclinet";
import Home1RowSeven from "../Home1/Home1RowSeven";
import FooterContact from "../../components/FooterContact";

function SuccessModal({ message, onClose }) {
  return (
    <div className="modal-overlay">
      <div className="modal-container">
        <h3>{message}</h3>
        <button onClick={onClose}>Okay</button>
      </div>
    </div>
  );
}

export default function EnglishCoachingPage() {
  const [selectedTab, setSelectedTab] = useState("TOEFL");
  
  // State hooks to manage form inputs
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [mobileNumber, setMobileNumber] = useState("");
  const [preferredDestination, setPreferredDestination] = useState("");
  const [studyPlan, setStudyPlan] = useState("");
  const [comments, setComments] = useState("");
  const [showSuccessModal, setShowSuccessModal] = useState(false);

  const receiverEmail = "jpetcsglobal@gmail.com"; // Predefined receiver email

  // Function to handle form submission and send email
  const handleFormSubmit = (e) => {
    e.preventDefault();

    const templateParams = {
      firstName,
      lastName,
      email,
      mobileNumber,
      preferredDestination,
      studyPlan,
      comments,
      receiver_email: receiverEmail, // Send to the predefined receiver's email
    };

    // EmailJS configuration and sending email
    emailjs
      .send(
        "service_hij3bpr", // Replace with your EmailJS service ID
        "template_s3if2fj", // Replace with your EmailJS template ID
        templateParams,
        "yUT7dkyQWAZoWKCES" // Replace with your EmailJS user ID
      )
      .then(
        (response) => {
          console.log("SUCCESS!", response.status, response.text);
          setShowSuccessModal(true);
          // Reset form fields
          setFirstName("");
          setLastName("");
          setEmail("");
          setMobileNumber("");
          setPreferredDestination("");
          setStudyPlan("");
          setComments("");
        },
        (error) => {
          console.log("FAILED...", error);
          alert("Failed to send the message. Please try again.");
        }
      );
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth", // This makes the scrolling behavior smooth
    });
  };

  const handleTabChange = (tab) => {
    setSelectedTab(tab);
  };

  const tabImages = {
    TOEFL: "images/te1.png",
    IELTS: "images/ielts.png",
    PTE: "images/pte.png",
    Duolingo: "images/duolingo.png",
    GMAT: "images/gmat.png",
  };

  
  return (
    <>
      <Helmet>
        <title>JP Edu Tech</title>
        <meta name="description" content="Web site created using create-react-app" />
      </Helmet>
      <style>
        {`
        @keyframes slideDown {
              0% {
                transform: translateY(-100%); /* Starts off-screen from the top */
                opacity: 0;
              }
              100% {
                transform: translateY(0); /* Ends in its original position */
                opacity: 1;
              }
            }

            .animate-slideDown {
              animation: slideDown 1.5s ease-in-out forwards; /* Adjust duration and timing function as needed */
            }

            @keyframes slideUp {
              0% {
                transform: translateY(100%); /* Starts off-screen from the top */
                opacity: 0;
              }
              100% {
                transform: translateY(0); /* Ends in its original position */
                opacity: 1;
              }
            }

            .animate-slideUp {
              animation: slideUp 1.5s ease-in-out forwards; /* Adjust duration and timing function as needed */
            }

            @keyframes leftToRight {
              0% {
                transform: translateX(-100%); /* Starts off-screen to the left */
                opacity: 0;
              }
              100% {
                transform: translateX(0); /* Ends in its original position */
                opacity: 1;
              }
            }

            .animate-leftToRight {
              animation: leftToRight 2s ease-in-out forwards; /* Adjust the duration as needed */
            }
               @keyframes RightToleft {
              0% {
                transform: translateX(100%); /* Starts off-screen to the left */
                opacity: 0;
              }
              100% {
                transform: translateX(0); /* Ends in its original position */
                opacity: 1;
              }
            }
             .animate-RightToleft{
             animation: RightToleft 2s ease-in-out backwards;
             } 
          @keyframes zoomInOut {
            0% {
              transform: scale(0.3); /* Normal size */
            }
            100% {
              transform: scale(1.1); /* Zoomed in */
            }
           
          }

          .animate-zoomInOut {
            animation: zoomInOut 3s ease-in-out forwards; /* No infinite, and forwards to keep final state */
          }

          .modal-overlay {
            position: fixed;
            top: 0;
            left: 0;
            width: 100vw;
            height: 100vh;
            background: rgba(0, 0, 0, 0.5);
            display: flex;
            justify-content: center;
            align-items: center;
            z-index: 1000;
          }

          .modal-container {
            background: white;
            padding: 20px;
            border-radius: 10px;
            width: 80%;
            max-width: 400px;
            text-align: center;
          }

          .modal-container h3 {
            margin-bottom: 20px;
          }

          .modal-container button {
            padding: 10px 20px;
            background-color: #1b5c82;
            color: white;
            border: none;
            border-radius: 5px;
            cursor: pointer;
          }

          .modal-container button:hover {
            background-color: #0056b3;
          }
        `}
      </style>
      {/*Header*/}
      <Header />
      <div className="banner_inner bg-[url(/public/images/img_group_278.png)] bg-cover bg-no-repeat relative english_banner">
        <div className="inner_bg trans">
          <div className="text-center">
            <h1 className="font6 font-bold text-white-a700_01 animate-zoomInOut">
              Unlock Your Potential with <br />
              Expert English Coaching!
            </h1>
            <a href="/enquirepage" className="d-inline-flex mt-8">
              <span className='submitBtn'>
                <span className='cursor-pointer text-capitalize'>Appointment</span>
              </span>
            </a>
          </div>
        </div>
      </div>

      <div className="resources w-100 black_img mb_70px english_coa">
        <div className="container">
          <div className="row flex-lg-row-reverse">
            <div className="col-lg-7">
              <div>
                <h6 className="font3 font-bold my-lg-4 my-2 tracking-[-0.91px] text-light_blue-900">Why English Proficiency Course marks are an important part of qualifying for studying abroad.</h6>
                <p className="font1_5 font-normal leading-[30px] tracking-[0.40px] text-white-a700_01">
                  To make sure that overseas students have the English language comprehension and communication
                  abilities needed to succeed in their chosen program, the majority of educational institutions
                  worldwide employ standardized English examinations.
                  <br /> 
                  <br />
                  At JPEDU TECH, we understand how each of the industry-recognized English tests is conducted and
                  what’s required of applicants.
                  <br /> 
                  <br />
                  Because of this, we provide students with the greatest exam preparation guidance available in JP
                  EDU TECH so they can get the score they want.
                </p>
                <button shape="square" className='submitBtn my-5 m-none'>
                  <span className='cursor-pointer text-capitalize'>Know More</span>
                </button>
              </div>
            </div>
            <div className="col-lg-5">
              <div className="relative w-75per">
                <Img
                  src="images/img_rectangle_4363.png"
                  alt="Imageteen"
                  className="animate-slideDown cen_img"
                />
                <Img
                  src="images/img_rectangle_4364.png"
                  alt="Imageteen"
                  className="absolute bot_img animate-slideUp"
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="bg-[url(/public/images/img_group_998.png)] bg-contain bg_F8FEFD bg_bottom bg-no-repeat w-100">
        <div className="container mw_1920">
          <div className="row">
            <div className="col-lg-8">
              <div className="w-100 mt-lg-4 mt-2">
                <Tabs
                  className="relative tab_parts py-lg-4 py-2"
                  selectedTabClassName="!text-white-a700_01 bg-light_blue-900 shadow-3xl font1_5 font-semibold"
                  selectedTabPanelClassName="tab-panel--selected"
                >
                  <TabList className="d-flex flex-wrap">
                    <Tab
                      className="px-8 py-tb2 mx-2 font1_5 font-semibold cursor-pointer transition duration-300 hover:bg-light_blue-900 hover:text-white-a700_01 shadow-md hover:shadow-lg"
                      onClick={() => handleTabChange("TOEFL")}
                    >
                      TOEFL
                    </Tab>
                    <Tab
                      className="px-8 py-tb2 mx-2 font1_5 font-semibold cursor-pointer transition duration-300 hover:bg-light_blue-900 hover:text-white-a700_01 shadow-md hover:shadow-lg"
                      onClick={() => handleTabChange("IELTS")}
                    >
                      IELTS
                    </Tab>
                    <Tab
                      className="px-8 py-tb2 mx-2 font1_5 font-semibold cursor-pointer transition duration-300 hover:bg-light_blue-900 hover:text-white-a700_01 shadow-md hover:shadow-lg"
                      onClick={() => handleTabChange("PTE")}
                    >
                      PTE
                    </Tab>
                    <Tab
                      className="px-8 py-tb2 mx-2 font1_5 font-semibold cursor-pointer transition duration-300 hover:bg-light_blue-900 hover:text-white-a700_01 shadow-md hover:shadow-lg"
                      onClick={() => handleTabChange("Duolingo")}
                    >
                      Duolingo
                    </Tab>
                    <Tab
                      className="px-8 py-tb2 mx-2 font1_5 font-semibold cursor-pointer transition duration-300 hover:bg-light_blue-900 hover:text-white-a700_01 shadow-md hover:shadow-lg"
                      onClick={() => handleTabChange("GMAT")}
                    >
                      GMAT
                    </Tab>
                    <a href="/enquirepage">
                      <Tab className="px-8 py-tb2 mx-2 font1_5 font-semibold cursor-pointer transition duration-300 hover:bg-light_blue-900 hover:text-white-a700_01 shadow-md hover:shadow-lg">
                        View All
                      </Tab>
                    </a>
                  </TabList>
                </Tabs>
                <div className="tab_con mt-4">
                  <Img src={tabImages[selectedTab]} alt={`${selectedTab} Image`} />
                </div>
                {/* English Coaching Column */}
                <EnglishcoachingColumnFive />
              </div>
              <div className="d-lg-none">
                <button shape="square" className='submitBtn mb-2'>
                  <span className='cursor-pointer text-capitalize'>Get Experts Advice</span>
                </button>
              </div>
            </div>
            <div className="col-lg-4 pe-0 bg-[url(/public/images/img_group_469_1.png)] bg-cover bg-no-repeat m-none">
              <div className="py-8 px-8">
              <h3 className="font3 font-bold text-white-a700 pb-2">Quick Enquiry</h3>
                <form className="form_design mt-4" onSubmit={handleFormSubmit}>
                  <div className="d-lg-flex">
                    <Input
                      size="lg"
                      shape="square"
                      type="text"
                      name="firstName"
                      placeholder="First Name"
                      value={firstName}
                      onChange={(e) => setFirstName(e.target.value)}
                      style={{ border: "none" }}
                      className="con_label w-100"
                      required
                    />
                    <Input
                      size="lg"
                      shape="square"
                      type="text"
                      name="lastName"
                      placeholder="Last Name"
                      value={lastName}
                      onChange={(e) => setLastName(e.target.value)}
                      style={{ border: "none" }}
                      className="con_label ms-lg-4 w-100"
                      required
                    />
                  </div>
                  <Input
                    size="lg"
                    shape="square"
                    type="email"
                    name="email"
                    placeholder="Email address"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    style={{ border: "none" }}
                    className="con_label w-100"
                    required
                  />
                  <Input
                    size="lg"
                    shape="square"
                    type="number"
                    name="mobileNumber"
                    placeholder="Mobile number"
                    value={mobileNumber}
                    onChange={(e) => setMobileNumber(e.target.value)}
                    style={{ border: "none" }}
                    className="con_label w-100"
                    required
                  />
                  <Input
                    size="lg"
                    shape="square"
                    name="preferredDestination"
                    placeholder="Your preferred study destination"
                    value={preferredDestination}
                    onChange={(e) => setPreferredDestination(e.target.value)}
                    style={{ border: "none" }}
                    className="con_label w-100"
                    required
                  />
                  <Input
                    size="lg"
                    shape="square"
                    name="studyPlan"
                    placeholder="When do you plan to study?"
                    value={studyPlan}
                    onChange={(e) => setStudyPlan(e.target.value)}
                    style={{ border: "none" }}
                    className="con_label w-100"
                    required
                  />
                  <textArea
                    size="lg"
                    shape="square"
                    name="comments"
                    placeholder="  Comments..."
                    value={comments}
                    onChange={(e) => setComments(e.target.value)}
                    style={{ border: '1px solid #ccc', height: '100px', width: '100%', padding: '10px' }}
                    className="con_label w-100"
                    required
                  />
                  <button shape="square" className="submitBtn mt-4 mb-4">
                    <span className="cursor-pointer text-capitalize">Get Experts Advice</span>
                  </button>
                </form>
                {showSuccessModal && (
                    <SuccessModal
                      message="Your message has been sent successfully!"
                      onClose={() => setShowSuccessModal(false)}
                    />
                  )}
                {/* Talk to Our Visa Experts Section */}
                <h3 className="font1_5 font-semibold tracking-[0.40px] text-white-a700_01">Talk to Our Visa Experts</h3>
                <div className="mt-4 d-flex align-items-center">
                  <Img src="/images/img_icon_white_a700_01.png" alt="Icon" className="w-40px" />
                  <p className="font1_5 font-normal text-white-a700_01 ms-4">
                    <span>+91 8247510882</span>
                    <span className="ms-4">+91 9704428144</span>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/*Testimonial*/}
      <Home1Rowourclinet />
      {/*Contact Form*/}
      <Home1RowSeven />
      <div className="container relative w-100">
        <div className='absolute scroll-index'>
          <span className="scroll-top d-flex align-items-center justify-content-center bg-light_blue-900 cursor-pointer"
            onClick={scrollToTop}>
            <Img src="images/img_vector_white_a700_01_14x24.svg" alt="Vector Eleven" className="h-[14px]" />
          </span>
        </div>
      </div>
      <FooterContact />
      {/*Footer*/}
      <Footer />
    </>
  );
}

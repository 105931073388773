import { Text, Img, Heading } from "../../components";
import React from "react";

export default function SoftwareRowportraitprof() {
  return (
    <>
    <div className="w-100 bg-contain bg_DFECEC91 bg_bottom bg-no-repeat">
      <div className="container">
        <div className="row">
          <div className="col-lg-5 ps-0">
            <div className="m-none">
              <Img src="images/img_portrait_profes.png"
                alt="Portraitprofes"
                className=""
              />
            </div>
          </div>
          <div className="col-lg-7">
            <div className="w-100">
              <h6 className="font3 mt-4 font-bold tracking-[-0.91px] text-light_blue-900">Why choose us?</h6>
              <p className="font1_5 font-normal leading-[30px] tracking-[0.40px] text-black-900_02 mt-2">Choose us for a partner who is invested in your success and committed to helping you navigate your path with
          confidence.</p>
              <div className="w-100">
                <div className="d-flex align-items-start w-100 mt-3">
                  <Img src="images/img_arrow_left.svg" alt="Arrowleft" className="mt-1.5 h-[22px]" />
                  <p className="font1_5 font-normal leading-[30px] tracking-[0.48px] text-gray-800 ms-lg-4 ms-2">
                    <span className="font-semibold">Expertise:&nbsp;</span>
                    <span>Our team consists of seasoned professionals with extensive experience in their respective
                    fields, ensuring you receive the highest quality advice and support.</span>
                  </p>
                </div>
                <div className="d-flex align-items-start w-100 mt-3">
                  <Img src="images/img_arrow_left.svg" alt="Arrowleft" className="mt-1.5 h-[22px]" />
                  <p className="font1_5 font-normal leading-[30px] tracking-[0.48px] text-gray-800 ms-lg-4 ms-2">
                    <span className="font-semibold">Personalized Solutions:&nbsp;</span>
                    <span>We tailor our services to meet your specific needs and goals, providing customized
                    strategies that align with your vision and objectives.</span>
                  </p>
                </div>
                <div className="d-flex align-items-start w-100 mt-3">
                  <Img src="images/img_arrow_left.svg" alt="Arrowleft" className="mt-1.5 h-[22px]" />
                  <p className="font1_5 font-normal leading-[30px] tracking-[0.48px] text-gray-800 ms-lg-4 ms-2">
                    <span className="font-semibold">Comprehensive Support:&nbsp;</span>
                    <span>From visa and immigration assistance to career counselling and English coaching,
                    we offer a wide range of services to support your journey from start to finish. Proven Track Record: We have a history of successfully helping clients achieve their personal and
                    professional goals, backed by positive testimonials and case studies.</span>
                  </p>
                </div>
                <div className="d-flex align-items-start w-100 mt-3">
                  <Img src="images/img_arrow_left.svg" alt="Arrowleft" className="mt-1.5 h-[22px]" />
                  <p className="font1_5 font-normal leading-[30px] tracking-[0.48px] text-gray-800 ms-lg-4 ms-2">
                    <span className="font-semibold">Commitment to Excellence:&nbsp;</span>
                    <span>We are dedicated to delivering exceptional service, staying up-to-date with
                    industry trends, and continuously improving our offerings to ensure your satisfaction.</span>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    </>
  );
}

import React, { useState, useEffect, Suspense, Autoplay } from 'react'; // Import useEffect
import { Helmet } from "react-helmet";
import "slick-carousel/slick/slick.css";  
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import { Text, Heading, Img, Button, Input, TextArea } from "../../components";
import Footer from "../../components/Footer";
import ChatModal from "../../components/ChatModal";
import { CloseSVG } from "../../components/Input/close.jsx";
import UserProfile from "../../components/UserProfile";
import Home1ColumnFour from "./Home1ColumnFour";
import Home1RowFive from "./Home1RowFive";
import Home1RowSeven from "./Home1RowSeven";
import Home1RowThree from "./Home1RowThree";
import Home1RowaboutusOne from "./Home1RowaboutusOne";
import Home1Rowourclinet from "./Home1Rowourclinet";
import FooterContact from "../../components/FooterContact";
import Brand from "../../components/Brand";
import { useLocation } from "react-router-dom";
import { useNavigate } from 'react-router-dom';  // Importing the useLocation hook



export default function Home1Page() {
  const [activeImageSet, setActiveImageSet] = useState(0); // State for image set
  const [searchBarValue, setSearchBarValue] = useState(''); 
  const [isChatOpen, setChatOpen] = useState(false); // State for chat modal
  const [selectedImageIndex, setSelectedImageIndex] = useState(0);
  const [showNavbar, setShowNavbar] = useState(false); // State for showing navbar

  // useEffect hook for automatically changing the image sets every 3 seconds
  useEffect(() => {
    const interval = setInterval(() => {
      setActiveImageSet((prevIndex) => (prevIndex + 1) % imageSets.length); // Cycle through image sets
    }, 3000);  // Change every 3 seconds

    return () => clearInterval(interval); // Cleanup interval on component unmount
  }, []); // Empty dependency array ensures it runs only once
  
  // useEffect hook to open the chat modal automatically after 5 seconds
  useEffect(() => {
    const timer = setTimeout(() => {
      setChatOpen(true); // Open the chat modal after 5 seconds
    }, 3000); 

    return () => clearTimeout(timer); // Cleanup timer on component unmount
  }, []);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth", // Smooth scroll effect
    });
  };
  
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    swipeToSlide: true,
    autoplay: true, // Enables autoplay
    autoplaySpeed: 3000, // Speed of autoplay in milliseconds
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          arrows: false,
        },
      },
    ],
  };
  

  const data = [
    {
      userImage: "images/img_rectangle_4186.png",
      hoverImage: "images/hover_img_rectangle_4186.png",
    },
    {
      userImage: "images/img_rectangle_4187.png",
      hoverImage: "images/hover_img_rectangle_4187.png",
    },
    {
      userImage: "images/img_rectangle_4188.png",
      hoverImage: "images/hover_img_rectangle_4188.png",
    },
    {
      userImage: "images/img_rectangle_4189.png",
      hoverImage: "images/hover_img_rectangle_4189.png",
    },
    {
      userImage: "images/img_rectangle_4190.png",
      hoverImage: "images/hover_img_rectangle_4190.png",
    },
    {
      userImage: "images/img_rectangle_4191.png",
      hoverImage: "images/hover_img_rectangle_4191.png",
    },
  ];
  

  
  // List of image sets
  const imageSets = [
    [
      {
        src: "images/img_rect001.png", 
        heading: "USA", 
        description: "The United States is the most popular destination for international students. It has a long history of welcoming foreign students and offers some of the best universities in the world. The US Student Visa is a Non-Immigrant Visa that allows foreign nationals to pursue academic studies, language training programs, or other types of vocational or technical training."
      },
      // { 
      //   src: "images/img_rect003.jpg", 
      //   heading: "China", 
      //   description: "China offers a unique blend of ancient traditions and modern innovation. With a rapidly growing economy, it's a hub for industries like technology, manufacturing, and finance. The rich cultural heritage, diverse landscapes, and vibrant cities provide an excellent environment for both personal and professional growth."
      // },
      {
        src: "images/img_rect007.jpg", 
        heading: "New Zealand", 
        description: "Migrating to New Zealand offers a high quality of life with stunning natural landscapes and a safe, friendly environment. The country boasts excellent healthcare, education, and a strong emphasis on work-life balance."
      },
      {
        src: "images/img_rect002.jpg", 
        heading: "Dubai", 
        description: "Migrating to Dubai offers a high standard of living, tax-free income, and thriving job market, particularly in finance, technology, and hospitality. The city boasts modern infrastructure, luxury amenities, and a cosmopolitan lifestyle."
      },
    ],
    [
      {
        src: "images/img_rect006.jpg", 
        heading: "Malaysia", 
        description: "Migrating to Malaysia offers a low cost of living, a diverse and vibrant culture, and beautiful natural landscapes. The country has a growing economy with abundant business opportunities and a welcoming environment for expatriates."
      },
      // {
      //   src: "images/img_rect004.jpg", 
      //   heading: "Indonesia", 
      //   description: "Migrating to Indonesia provides access to a rich cultural heritage, stunning landscapes, and a relatively low cost of living. The country's vibrant economy offers diverse opportunities, while its welcoming communities and warm climate enhance quality of life."
      // },
      {
        src: "images/img_rect008.jpg", 
        heading: "Poland", 
        description: "Migrating to Poland provides access to a dynamic and growing economy, vibrant cultural experiences, and a high quality of life. The country’s modern infrastructure and historical landmarks make it an attractive place for both personal and professional opportunities."
      },
      {
        src: "images/img_rect005.jpg", 
        heading: "Ireland", 
        description: "Migrating to Ireland offers a high quality of life with stunning landscapes and a welcoming, friendly atmosphere. The country provides excellent healthcare, education, and a strong job market, particularly in tech and pharmaceuticals."
      },

    ],
    [


      {
        src: "images/img_rect009.jpg", 
        heading: "Singapore", 
        description: "Migrating to Singapore provides access to a thriving economy, excellent healthcare, and world-class education. The city-state offers a high standard of living and a vibrant, multicultural environment. Its strategic location and robust infrastructure also make it a key global business hub."
      }
    ]
  ];

  const keywordToRouteMapping = {
    // Home and General Navigation
    "home": "/home",
    "welcome": "/home",
    
    // Study Abroad and Education Keywords
    "study": "/studyabroad",
    "abroad": "/studyabroad",
    "education": "/studyabroad",
    "international": "/studyabroad",
    "overseas": "/studyabroad",
    "university": "/studyabroad",
    "college": "/studyabroad",
    "scholarship": "/studyabroad",
    "admissions": "/studyabroad",
    "exchange": "/studyabroad",
    "guidance": "/studyabroad",
  
    // Software Consulting Keywords
    "software": "/software",
    "consulting": "/software",
    "technology": "/software",
    "it": "/software",
    "digital": "/software",
    "development": "/software",
    "application": "/software",
  
    // Migration Services Keywords
    "migrate": "/Migrate",
    "immigration": "/Migrate",
    "visa": "/Migrate",
    "permanent": "/Migrate",
    "residency": "/Migrate",
    "citizenship": "/Migrate",
    "worker": "/Migrate",
    "family": "/Migrate",
    "tourist": "/Migrate",
    "pr": "/Migrate",
    "application": "/Migrate",
    
    // English Coaching Keywords
    "english": "/EnglishCoaching",
    "coaching": "/EnglishCoaching",
    "language": "/EnglishCoaching",
    "ielts": "/EnglishCoaching",
    "toefl": "/EnglishCoaching",
    "test": "/EnglishCoaching",
    "training": "/EnglishCoaching",
    "exam": "/EnglishCoaching",
  
    // About Us Keywords
    "about": "/aboutus",
    "mission": "/aboutus",
    "team": "/aboutus",
    "vision": "/aboutus",
    "values": "/aboutus",
  
    // Resources Keywords
    "resources": "/Resources",
    "support": "/Resources",
    "documentation": "/Resources",
    "materials": "/Resources",
  
    // Contact Us Keywords
    "contact": "/ContactUs",
    "reach": "/ContactUs",
    "connect": "/ContactUs",
    "get in touch": "/ContactUs",
  
    // Enquire Keywords
    "enquire": "/Enquirepage",
    "inquire": "/Enquirepage",
    "info": "/Enquirepage",
    "information": "/Enquirepage",
    "query": "/Enquirepage",
  };
  
  
  
  const { heading, description } = imageSets[activeImageSet]?.[selectedImageIndex] || {};
  const handleDotClick = (index) => {
    setActiveImageSet(index); // Switch the image set when a dot is clicked
  };

  const toggleChat = () => {
    setChatOpen(!isChatOpen); // Toggle chat modal visibility
  };

  const handleMouseEnter = () => {
    const element = document.querySelector(".vector-animate");
    element.classList.add("vector-animate-right");
    element.classList.remove("vector-animate-left");
  };

  const handleMouseLeave = () => {
    const element = document.querySelector(".vector-animate");
    element.classList.add("vector-animate-left");
    element.classList.remove("vector-animate-right");
  };


  const handleShowNavbar = () => {
    setShowNavbar(!showNavbar);
  };

  const location = useLocation(); // Hook to get the current route
  // Function to check if the current path matches the given route
  const isActiveRoute = (route) => location.pathname === route;

  const navigate = useNavigate(); // Use the hook to navigate programmatically

  // Function to handle search redirection
  const handleSearchSubmit = () => {
    const searchTerm = searchBarValue.trim().toLowerCase();
  
    // Check if search term matches any route key directly
    const matchedRoute = Object.keys(keywordToRouteMapping).find(keyword => 
      searchTerm.includes(keyword)
    );
  
    // Navigate to the matched route or show a page not found message
    if (matchedRoute) {
      navigate(keywordToRouteMapping[matchedRoute]);
    } else {
      alert("Page not found! Please check your search term.");
    }
  };
  

  return (
    <>
      <Helmet>
        <title>JP Edu Tech - Study Abroad & Migration Services</title>
        <meta name="description" content="JP Edu Tech provides expert guidance on studying abroad, migration services, English coaching, and software consulting. Discover your path with JP Edu Tech!" />
        <meta name="keywords" content="JP Edu Tech, Study Abroad, Migration Services, English Coaching, Software Consulting" />
        <meta name="author" content="JP Edu Tech" />
        <meta property="og:title" content="JP Edu Tech - Study Abroad & Migration Services" />
        <meta property="og:description" content="Expert guidance on studying abroad, migration services, English coaching, and software consulting. Discover your path with JP Edu Tech!" />
        <meta property="og:image" content="https://appproject.klippefort.com/klippefort-logo.png" />
        <meta property="og:url" content="https://jpetcs.com/" />
        <meta property="og:type" content="website" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="JP Edu Tech - Study Abroad & Migration Services" />
        <meta name="twitter:description" content="JP Edu Tech provides expert guidance on studying abroad, migration services, English coaching, and software consulting." />
        <meta name="twitter:image" content="https://jpetcs.com/favicon.ico" />
      </Helmet>
      <style>
        {`

        /* Styles for the chat icon container */
.chat-icon-container {
  position: fixed;
  bottom: 20px;
  right: 20px; /* Adjust this value as needed */
  z-index: 10000;
  cursor: pointer;
  padding: 8px; /* Add padding to give some space around the icon */
  transition: transform 0.3s ease-in-out;
}

@media (max-width: 768px) {
  .chat-icon-container {
    right: 10px; /* Reduce the right margin on smaller screens */
    bottom: 15px; /* Adjust the bottom margin to suit mobile view */
  }
}

.chat-icon-container:hover {
  transform: scale(1.1); /* Slight zoom effect on hover */
}


/* Styles for the chat modal backdrop */
.chat-modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1050; /* Ensure it's above all elements */
}

/* Chat Modal itself */
.chat-modal {

  width: 100%;
  height: auto;
  max-width: 400px;
  z-index: 10000;
  position: relative; /* Make the modal content stay within the container */
  padding: 20px; /* Add some padding to the modal */
}

        /* Custom Scrollbar for Webkit Browsers (Chrome, Safari) */
          ::-webkit-scrollbar {
            width: 10px; /* Width of the scrollbar */
            height: 10px; /* Height for horizontal scrollbar */
          }

          ::-webkit-scrollbar-track {
            background: #1B5D82; /* Background of scrollbar track */
            border-radius: 10px; /* Rounded corners */
          }

          ::-webkit-scrollbar-thumb {
            background-color: #1B5D82; /* Color of the thumb */
            border-radius: 10px; /* Rounded thumb corners */
            border: 2px solid transparent; /* Space around thumb */
            background-clip: content-box; /* Prevent the border from overlapping */
          }

          /* Hover effect on scrollbar thumb */
          ::-webkit-scrollbar-thumb:hover {
            background-color: #1B5D82;
          }

          /* Custom Scrollbar for Firefox */
          html {
            scrollbar-width: thin;
            scrollbar-color: #1B5D82 #f1f1f1; /* Thumb color and track color */
          }

          /* Scrollbar for the Container */
          .scroll-container {
            max-height: 100vh; /* Full-page height scroll */
            overflow-y: auto; /* Enable vertical scroll */
            padding-right: 15px; /* Avoid content overlapping scrollbar */
          }

          @keyframes slideRight {
            from {
              transform: translateX(0);
            }
            to {
              transform: translateX(calc(100% + 300px));
            }
          }

          @keyframes slideLeft {
            from {
              transform: translateX(calc(100% + 300px));
            }
            to {
              transform: translateX(0);
            }
          }

          .vector-animate-right {
            animation: slideRight 5s forwards;
          }

          .vector-animate-left {
            animation: slideLeft 5s forwards;
          }

          .chat-modal-backdrop {
            position: fixed;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-color: rgba(0,0,0,0.5); /* Dim the background to focus on the chat modal */
            display: flex;
            align-items: center;
            justify-content: center;
            z-index: 1050; /* High enough to be above most other content */
          }

          .chat-modal {

            width: 100%;
            height: auto;
            max-width: 25%; /* Adjust width as needed */
            z-index: 10000; /* Even higher to ensure the modal itself is above the backdrop */
            position: absolute; /* This will position the modal fixed within the viewport */
            bottom: 10%; /* Raise it slightly from the bottom */
            transform: translateX(-50%); /* Center it horizontally relative to the button */
            
            margin-left:96%;
          }
            @keyframes slideDown {
              0% {
                transform: translateY(-100%); /* Starts off-screen from the top */
                opacity: 0;
              }
              100% {
                transform: translateY(0); /* Ends in its original position */
                opacity: 1;
              }
            }

            .animate-slideDown {
              animation: slideDown 1.5s ease-in-out forwards; /* Adjust duration and timing function as needed */
            }

            @keyframes slideUp {
              0% {
                transform: translateY(100%); /* Starts off-screen from the top */
                opacity: 0;
              }
              100% {
                transform: translateY(0); /* Ends in its original position */
                opacity: 1;
              }
            }

            .animate-slideUp {
              animation: slideUp 1.5s ease-in-out forwards; /* Adjust duration and timing function as needed */
            }

            @keyframes leftToRight {
              0% {
                transform: translateX(-100%); /* Starts off-screen to the left */
                opacity: 0;
              }
              100% {
                transform: translateX(0); /* Ends in its original position */
                opacity: 1;
              }
            }

            .animate-leftToRight {
              animation: leftToRight 2s ease-in-out forwards; /* Adjust the duration as needed */
            }
               @keyframes RightToleft {
              0% {
                transform: translateX(100%); /* Starts off-screen to the left */
                opacity: 0;
              }
              100% {
                transform: translateX(0); /* Ends in its original position */
                opacity: 1;
              }
            }
             .animate-RightToleft{
             animation: RightToleft 2s ease-in-out backwards;
             } 
          @keyframes zoomInOut {
            0% {
              transform: scale(0.3); /* Normal size */
            }
            100% {
              transform: scale(1.1); /* Zoomed in */
            }
           
          }
          .animate-zoomInOut {
            animation: zoomInOut 3s ease-in-out forwards; /* No infinite, and forwards to keep final state */
          }

          @media (max-width: 992px) {
            .header-section .row {
              flex-wrap: nowrap;
            }

            .header-section .col-5,
            .header-section .col-12,
            .header-section .col-7 {
              flex: 1;
              max-width: 33.33%;
            }

            .header-section {
              flex-grow: 1;
            }
            .header_search {
             flex-grow: 1;
             width: 100%; /* Ensure it takes full width */
             max-width: 600px; /* Optional: Add a max-width to prevent it from becoming too wide */
             margin: 0 auto;
            }
            .header-section .menu-icon,
            .header-section .ms-3 {
              padding-left: 0.5rem;
            }

            .header-section .col-12 {
              display: flex;
              justify-content: center;
              align-items: center;
            }
          }
        `}
      </style>
      {/*Header*/}
      <header className="header-section">
  <div className="container">
    <div className="row align-items-center">
      {/* Logo */}
      <div className="col-lg-2 col-5 d-flex">
        <Img src="images/img_logos1_2.png" alt="Logos1two" className="headerlogo" />
      </div>

      {/* Mobile Search Bar */}
      <div className="col-12 d-lg-none d-flex">
        <div className="w-100">
          <Input
            size="xs"
            shape="round"
            name="search"
            placeholder="Search your destination..."
            value={searchBarValue}
            onChange={(e) => setSearchBarValue(e.target.value)}
            suffix={
              <div className="flex h-[18px] w-[18px] items-center justify-center">
                {searchBarValue?.length > 0 ? (
                  <CloseSVG onClick={() => setSearchBarValue("")} height={12} width={12} />
                ) : (
                  <Img
                    src="images/img_rewind.svg"
                    alt="Rewind"
                    className="my-1.5 h-[18px] w-[18px]"
                  />
                )}
              </div>
            }
            className="bg-white w-100 d-flex align-items-center header_search text-blue_gray-300"
          />
        </div>
      </div>

      {/* Mobile Icons */}
      <div className="col-7 d-lg-none d-flex align-items-center justify-content-end">
        <div className="ms-3">
          <a href="/Enquirepage" target="_self">
            <Img src="images/call_now.png" alt="call_now" className="w-18px" />
          </a>
        </div>
        <div className="menu-icon ms-3" onClick={handleShowNavbar}>
          <Img src="images/hamburger_icon.png" alt="Hamburger" className="w-18px" />
        </div>
      </div>

      {/* Desktop Search Bar */}
      <div className="col-lg-4 d-lg-block d-none">
          <Input
            size="xs"
            shape="round"
            name="search"
            placeholder="Search your destination..."
            value={searchBarValue}
            onChange={(e) => setSearchBarValue(e.target.value)}
            onKeyDown={(e) => {
              if (e.key === "Enter") handleSearchSubmit(); // Check for Enter key
            }}
            suffix={
              <div className="flex h-[18px] w-[18px] items-center justify-center">
                {searchBarValue?.length > 0 ? (
                  <CloseSVG onClick={() => setSearchBarValue("")} height={18} width={18} />
                ) : (
                  <Img
                    src="images/img_rewind.svg"
                    alt="Rewind"
                    className="my-1.5 h-[18px] w-[18px]"
                  />
                )}
              </div>
            }
            className="bg-white w-100 d-flex align-items-center header_search text-blue_gray-300"
          />
        </div>

      {/* Desktop Icons and Enquire Button */}
      <div className="col-lg-6 d-lg-flex align-items-center justify-content-end d-none">
        <div className="header_socialIcon d-flex align-items-center">
          <a href="https://www.facebook.com/share/XL5X5UrAG4VuFYuy/?mibextid=qi2Omg" target="_blank">
            <Img src="images/img_facebook.svg" alt="Facebook" />
          </a>
          <a href="https://wa.me/message/2I52EUSWYTQBG1" target="_blank">
            <Img src="images/img_volume.svg" alt="Volume" />
          </a>
          <a href="https://www.instagram.com/jpetcs?igsh=MW5xNjF3aDN5dTE3bw==" target="_blank">
            <Img src="images/img_info.svg" alt="Info" />
          </a>
          <a href="https://x.com/jpedutech" target="_blank">
            <Img src="images/img_vector.svg" alt="Vector" />
          </a>
        </div>
        <a
          href="/Enquirepage"
          target="_self"
          className="ms-2 px-3 py-2 fs-6 d-inline-flex justify-content-center font-bold text-white bg-light_blue-900"
        >
          Enquire Now
        </a>
      </div>
    </div>
  </div>
</header>



      {/*banner*/}
      <div className='banner relative' >
        <div className='container'>
          <div className='row'>
            <div className="col-lg-6">
              <div className='mt-6'>
                <h1 className='font-playfairdisplay font5 font-black color_3A3F50 animate-leftToRight'>Expert Guidance</h1>
                <h2 className='ms-lg-2 my-lg-10 my-4 font4 font-satisfy font-normal tracking-\[3\.84px\] text-light_blue-900 animate-leftToRight'>For Your Journey Ahead</h2>
                <h3 className='font3 font-bold tracking-\[2\.88px\] text-blue_gray-800 animate-leftToRight'>Let’go!</h3>
                <p className='my-8 bannerP animate-leftToRight'>Welcome to JP Edu Tech! Let’s Discover Your Path Together. 
                    Find Your Destination with JP Edu Tech – Your Adventure Awaits! Hey There! 
                    Ready to Explore New Horizons with JP Edu Tech? Let’s Go! Join Us at JP Edu Tech – Where Learning is a Journey, 
                    Not a Destination. Step Into Your Future with JP Edu Tech – We’re Here to Guide You!</p>
                <button shape="square" className='submitBtn  animate-leftToRight'>
                  <span className='cursor-pointer text-capitalize' onClick={() => window.open("/enquirepage", "_self", "noopener,noreferrer")} >Learn More</span>
                </button>
                <div className="plane relative mt-8" onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
                  <Img src="images/Vector-2.png" alt="Base Image" className="absolute left-0 w-full vector_2" />
                  <Img src="images/Vector-1.png" alt="Sliding Image" className="absolute top-0 left-0 vector_1 vector-animate" />
                </div>
              </div>
            </div>
            <div className="col-lg-6 p-0 relative">
              <div className='bg-[url(/public/images/img_group_2364.png)] d-lg-block d-none pos-cen relative w-100 h-100 bg-cover bg-no-repeat'>
              </div>
              <Img src="images/img_group_7.png"alt="Image" className="helicopter absolute animate-slideDown" />
            </div>
          </div>
        </div>
      </div>
      {/*chat*/}
      <div className="chat-icon-container" onClick={toggleChat}>
  <div className="jetbox cursor-pointer">
    <img src="images/img_ellipse_67.png" alt="Chatbot Trigger" className="w-100 rounded-full object-cover"/>
    <div className="absolute inset-0 flex items-center justify-center font-bold text-white tracking-wider z-20">
      JET
    </div>
  </div>
  
  {/* Modal should be conditionally rendered outside of any <a> or similar tag */}
  {isChatOpen && (
    <div className="chat-modal-backdrop" onClick={toggleChat}>
      <div className="chat-modal" onClick={(e) => e.stopPropagation()}>
        <ChatModal isOpen={isChatOpen} onClose={() => setChatOpen(false)} />
      </div>
    </div>
  )}
</div>

      {/*menu*/}
      <nav className="my-lg-4">
        <div className="container">
          <div className='home_nav main_menu'>
            <div className={`nav-elements  ${showNavbar && "active"}`}>
              <div className='py-2 px-3 d-lg-none d-flex align-items-center justify-content-between'>
                <Img src="images/img_logos1_2.png" alt="Logos1two" className="headerlogo" />
                <span className='d-flex align-items-center justify-content-center menu_close' onClick={handleShowNavbar}>X</span>
              </div>
              <ul className='d-lg-flex justify-content-center'>
                <li className='d-lg-none'>
                  <a href="/home" target="_self" className={`${
                      isActiveRoute("/home") ? "activeColor" : ""
                    }`}>Home</a>
                  </li>
                <li>
                  <a href="/studyabroad" target="_self" className={`${
                      isActiveRoute("/studyabroad") ? "activeColor" : ""
                    }`}>Study Abroad</a>
                </li>
                <li>
                  <a href="/software" target="_self" className={`${
                      isActiveRoute("/software") ? "activeColor" : ""
                    }`}>Software Consulting</a>
                </li>
                <li>
                  <a href="/Migrate" target="_self" className={`${
                      isActiveRoute("/Migrate") ? "activeColor" : ""
                    }`}>Migrate</a></li>
                <li>
                  <a href="/EnglishCoaching" target="_self" className={`${
                      isActiveRoute("/EnglishCoaching") ? "activeColor" : ""
                    }`}>English Coaching</a>
                </li>
                <li>
                  <a href="/aboutus" target="_self" className={`${
                      isActiveRoute("/aboutus") ? "activeColor" : ""
                    }`}>About Us</a>
                </li>
                <li>
                  <a href="/Resources" target="_self" className={`${
                      isActiveRoute("/Resources") ? "activeColor" : ""
                    }`}>Resources</a>
                </li>
                <li>
                  <a href="/ContactUs" target="_self" className={`${
                      isActiveRoute("/ContactUs") ? "activeColor" : ""
                    }`}>Contact Us</a>
                </li>
              </ul>
              <div className='pt-4 px-3 d-lg-none d-flex align-items-center justify-content-between'>
                <a href="/Enquirepage" target="_self" className="ms-2 px-4 py-2 fs-6 d-inline-flex justify-content-center font-bold text-white bg-light_blue-900">
                  Enquire Now
                </a>
              </div>
            </div>
          </div>
        </div>
      </nav>

      {/*destination*/}
      <div className='choose_destination w-100 bg_tower bg-cover bg-no-repeat relative'>
  <div className="container">
    <div className="row">
      <div className="col-lg-6 col-12"> {/* Updated col-12 for mobile view */}
        <div className='pt-lg-8'>
          <Img src='images/img_close_light_blue_900.svg' className="m-none" alt='' />
          <div className='pt-8'>
            <h1 className='font-playfairdisplay font5 font-medium color_3A3F50 animate-leftToRight'>
              Choose Your own Destination
            </h1>
            <div className="ml-80 mt-2 h-[2px] w-[15%] bg-light_blue-900 animate-leftToRight m-none"></div>
          </div>
          <p className='py-8 bannerP animate-leftToRight'>
            Studying abroad opens doors to new cultures, experiences, and opportunities. At Jp Edu Tech,
            we are dedicated to helping you navigate the complexities of studying in a foreign country,
            ensuring a smooth and successful international academic experience.
          </p>
          <a href="/studyabroad" target="_self" className='animate-leftToRight learn'>
          <button className='submitBtn mt-4 md:mt-0'>
          <span className='cursor-pointer text-capitalize' onClick={() => window.open("/enquirepage", "_self", "noopener,noreferrer")}>
            View More
          </span>
        </button>
          </a>
        </div>
      </div>

      {/* Second Column Section */}
      <div className="col-lg-6 col-12 mt-10 mt-lg-0">
  <div
    className="relative m-auto flex flex-col items-start bg-white-a700_01 px-[32px] py-[18px] shadow-xl sm:px-4 animate-zoomInOut"
    style={{ maxWidth: "80%", marginLeft: "auto", marginRight: "auto" }}
  >
    {/* Dynamic Heading */}
    <h3 className="text-xl font-bold text-gray-800 mb-4">{heading}</h3>

    {/* Dynamic Description */}
    <p className="text-xs text-gray-700 mb-6 leading-relaxed">{description}</p>

    {/* Display Images with Click Event */}
    <div className="flex justify-evenly gap-2 mb-4 w-full">
      {imageSets[activeImageSet]
        .slice(0, window.innerWidth <= 768 ? 2 : imageSets[activeImageSet].length) // Show only 2 images in mobile view
        .map((img, index) => (
          <img
            key={index}
            src={img.src}
            alt={`Image ${index + 1}`}
            onClick={() => setSelectedImageIndex(index)}
            className={`w-32 h-32 object-cover cursor-pointer ${
              selectedImageIndex === index
                ? "border-4 border-blue-500 opacity-75"
                : ""
            }`}
          />
        ))}
    </div>

    {/* Navigation Dots to Change Image Set */}
    <div className="flex justify-center gap-2 sm:gap-3 w-full">
      {imageSets.map((_, index) => (
        <button
          key={index}
          onClick={() => setActiveImageSet(index)}
          className={`w-2 h-2 sm:w-3 sm:h-3 md:w-4 md:h-4 flex justify-center rounded-full ${
            activeImageSet === index ? "bg-blue_gray-900_ce" : "bg-blue_gray-900_44"
          }`}
        />
      ))}
    </div>
  </div>
</div>

    </div>
  </div>
</div>

      {/*our services*/}
      <div className='our_service'>
      <div className='relative'>
        <Img src="images/img_group_8.png" alt="Image" className="absolute nest m-none" />
      </div>

      <div className="container">
        <div className="row">
          {/* Left Column */}
          <div className="col-lg-6 ps-0">
            <Img src="images/Rectangle 4185.png" alt="Image" className="m-none" />
          </div>

          {/* Right Column */}
          <div className="col-lg-6">
            <div className='pt-lg-8 pl-lg-6'>
              <div className='pt-8'>
                <h1 className='font-playfairdisplay font5 font-medium color_3A3F50 animate-RightToleft'>
                  Our Services
                </h1>
                <div className="ml-80 mt-2 h-[2px] w-[15%] bg-light_blue-900 animate-RightToleft"></div>
              </div>
              <p className='py-8 bannerP animate-RightToleft relative'>
                Our Services at Jp Edu Tech offer tailored solutions for your educational and career goals. Explore study abroad programs, secure international internships, and find work opportunities worldwide. We provide expert guidance and support every step of the way. Let us help you achieve your dreams and succeed on a global scale!
              </p>
              <a href="/enquirepage" target="_self" className='animate-RightToleft'>
                <button className='submitBtn'>
                  <span className='cursor-pointer text-capitalize'>
                    Learn More
                  </span>
                </button>
              </a>
            </div>
          </div>
        </div>

        {/* Mobile View: Swipeable Carousel */}
        <div className='d-lg-none my-4'>
          <Slider {...settings}>
            {data.map((d, index) => (
              <div key={index} className="px-2">
                <div
                  className="position-relative"
                  onMouseEnter={(e) => e.currentTarget.querySelector('img').src = d.hoverImage}
                  onMouseLeave={(e) => e.currentTarget.querySelector('img').src = d.userImage}
                >
                  <Img
                    src={d.userImage}
                    alt={`Service ${index + 1}`}
                    className="w-100 object-cover"
                  />
                </div>
              </div>
            ))}
          </Slider>
        </div>
      </div>

      {/* Suspense and UserProfile Components */}
      <div className='relative m-none'>
        <div className="absolute service_bottom">
          <Suspense fallback={<div>Loading feed...</div>}>
            <div className="mt-10 flex justify-center">
              {data.map((d, index) => (
                <UserProfile
                  userImage={d.userImage}
                  hoverImage={d.hoverImage}
                  userRole={d.userRole}
                  key={`listsoftware_${index}`}
                />
              ))}
            </div>
          </Suspense>
        </div>
      </div>
    </div>

      {/*Migrate*/}
      <Home1ColumnFour />
      {/*Coaching*/}
      <Home1RowThree />
      {/*Aboutus*/}
      <Home1RowaboutusOne />
      {/*Resources*/}
      <Home1RowFive />
      {/*Brand*/}
      <Brand />
      {/*Testimonial*/}
      <Home1Rowourclinet />
      {/*Contact Form*/}
      <Home1RowSeven />
      <div className="container relative w-100">
        <div className='absolute scroll-index'>
          <span className="scroll-top d-flex align-items-center justify-content-center bg-light_blue-900 cursor-pointer"
            onClick={scrollToTop}>
            <Img src="images/img_vector_white_a700_01_14x24.svg" alt="Vector Eleven" className="h-[14px]" />
          </span>
        </div>
      </div>
      <FooterContact />
      {/*Footer*/}
      <Footer />
      <div className={`drawer-overlay  ${showNavbar && "active"}`} onClick={handleShowNavbar}></div>
    </>
  );
}

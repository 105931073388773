import { Text, Heading } from "../../components";
import React from "react";

export default function SoftwareRowThree() {
  return (
    <>
    <div className="bg-[url(/public/images/img_group_2267.png)] bg-cover bg-no-repeat">
      <div className="bg-black-900_e0 pa10">
        <div className="container">
          <div className="row">
            <div className="col-lg-4">
              <div className="text-center w-90per mx-auto">
                <h2 className="font2 font-nunitosans font-bold tracking-[-0.61px] text-white-a700_01">Software Design</h2>
                <p className="font1_5 font-normal leading-[30px] tracking-[0.40px] text-white-a700_01 my-3">Our Software Design consulting services focus on creating innovative and user-centric digital experiences. 
                We combine strategic thinking with design expertise to deliver solutions that drive business growth.</p>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="text-center w-90per mx-auto relative">
                <span className="soft_line"></span>
                <h2 className="font2 font-nunitosans font-bold tracking-[-0.61px] text-white-a700_01">Software Development</h2>
                <p className="font1_5 font-normal leading-[30px] tracking-[0.40px] text-white-a700_01 my-3">Our Software Development consulting services are designed to propel your business forward. 
                We offer a strategic approach to transforming your ideas into robust, scalable software solutions.</p>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="text-center w-90per mx-auto relative">
                <span className="soft_line lef-90"></span>
                <h2 className="font2 font-nunitosans font-bold tracking-[-0.61px] text-white-a700_01">Software Testing</h2>
                <p className="font1_5 font-normal leading-[30px] tracking-[0.40px] text-white-a700_01 my-3">We understand that robust testing is the cornerstone of high-quality software. 
                Our Software Testing consulting services are designed to identify and mitigate risks, ensuring your product meets the highest standards.</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    </>
  );
}

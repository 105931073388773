import { Text, Heading, Img } from "./..";
import React from "react";

export default function ContactDetails({
  callIcon = "images/img_call.svg",
  contactImage = "images/img_rectangle_4439.png",
  ...props
}) {
  return (
    <div {...props} className={`${props.className} col-lg-4`}>
      <div className="flex w-full flex-col items-center">
        <div className="relative z-[2] call_icon rounded-full border-solid border-light_blue-900 bg-white-a700_01">
          <Img src={callIcon} alt="Call" className="" />
        </div>
        <div className="relative t50">
          <Img
            src={contactImage}
            alt="Contact Details"
            className="mx-auto w-90per rounded-[10px]"
          />
          <div className="absolute bottom-0 left-0 right-0 top-0 m-auto flex h-max flex-1 flex-col items-start gap-5 rounded-[10px] py-[50px] pl-[94px] pr-14 md:p-5">
            <Heading size="heading3xl" as="h2" className=" mt-[86px] text-[32px] font-semibold text-white-a700_01">
            </Heading>
            <Text
              size="textxl"
              as="p"
              className="text-center text-[24px] font-medium leading-[45px] text-white-a700_01"
            >
            </Text>
          </div>
        </div>
      </div>
    </div>
  );
}

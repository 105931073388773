import { Button, Img } from "./..";
import React, { useState } from "react";
import { useLocation } from "react-router-dom";  // Importing the useLocation hook
import { Link } from "react-router-dom";

export default function Header(props) {

  const [showNavbar, setShowNavbar] = React.useState(false);

  const handleShowNavbar = () => {
    setShowNavbar(!showNavbar);
  };

  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const location = useLocation(); // Hook to get the current route

  // Function to check if the current path matches the given route
  const isActiveRoute = (route) => location.pathname === route;

  return (

    <>
    <div className={`drawer-overlay  ${showNavbar && "active"}`} onClick={handleShowNavbar}></div>
    <header className='header-section'>
      <div className='container'>
        <div className='row align-items-center'>
        <div className='col-lg-1 col-5'>
          <a href="/home">
            <Img src="images/img_logos1_2.png" alt="Logos1two" className="headerlogo" />
          </a>
        </div>
          <div className='col-7 d-lg-none d-flex align-items-center justify-content-end'>
            <div className='ms-3'>
              <Img src="images/search_icon.png" alt="search" className="w-18px" />
            </div>
            <div className='ms-3'>
              <Img src="images/call_now.png" alt="call_now" className="w-18px" />
            </div>
            <div className="menu-icon ms-3" onClick={handleShowNavbar}>
              <Img src="images/hamburger_icon.png" alt="Logos1two" className="w-18px" />
            </div>
          </div>
          <div className='col-lg-9'>
            <nav className="inner_menu">
              <div className='main_menu'>
                <div className={`nav-elements  ${showNavbar && "active"}`}>
                  <div className='py-2 px-3 d-lg-none d-flex align-items-center justify-content-between'>
                    <Img src="images/img_logos1_2.png" alt="Logos1two" className="headerlogo" />
                    <span className='d-flex align-items-center justify-content-center menu_close' onClick={handleShowNavbar}>X</span>
                  </div>
                  <ul className='d-lg-flex justify-content-center'>
                    <li>
                      <a href="/home" target="_self" className={`${
                          isActiveRoute("/home") ? "activeColor" : ""
                        }`}>Home</a>
                      </li>
                    <li>
                      <a href="/studyabroad" target="_self" className={`${
                          isActiveRoute("/studyabroad") ? "activeColor" : ""
                        }`}>Study Abroad</a>
                    </li>
                    <li>
                      <a href="/software" target="_self" className={`${
                          isActiveRoute("/software") ? "activeColor" : ""
                        }`}>Software Consulting</a>
                    </li>
                    <li>
                      <a href="/Migrate" target="_self" className={`${
                          isActiveRoute("/Migrate") ? "activeColor" : ""
                        }`}>Migrate</a></li>
                    <li>
                      <a href="/EnglishCoaching" target="_self" className={`${
                          isActiveRoute("/EnglishCoaching") ? "activeColor" : ""
                        }`}>English Coaching</a>
                    </li>
                    <li>
                      <a href="/aboutus" target="_self" className={`${
                          isActiveRoute("/aboutus") ? "activeColor" : ""
                        }`}>About Us</a>
                    </li>
                    <li>
                      <a href="/Resources" target="_self" className={`${
                          isActiveRoute("/Resources") ? "activeColor" : ""
                        }`}>Resources</a>
                    </li>
                    <li>
                      <a href="/ContactUs" target="_self" className={`${
                          isActiveRoute("/ContactUs") ? "activeColor" : ""
                        }`}>Contact Us</a>
                    </li>
                  </ul>
                  <div className='pt-4 px-3 d-lg-none d-flex align-items-center justify-content-between'>
                    <a href="/Enquirepage" target="_self" className="ms-2 px-4 py-2 fs-6 d-inline-flex justify-content-center font-bold text-white bg-light_blue-900">
                      Enquire Now
                    </a>
                  </div>
                </div>
              </div>
            </nav>
          </div>
          <div className='col-lg-2'>
            <div className='d-lg-flex align-items-center justify-content-end d-none'>
              <Img src="images/search_icon.png" alt="search" className="h-[22px] w-[22px] me-2" />
              <a href="/Enquirepage" target="_self" className="ms-2 px-3 py-2 fs-6 d-inline-flex justify-content-center font-bold text-white bg-teal-400">
                Enquire Now
              </a>
            </div>
          </div>
        </div>
      </div>
    </header>
    </>
  );
}

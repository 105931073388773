import React, { useState, useRef } from "react";
import { Helmet } from "react-helmet";
import { Img, TextArea, Input, Slider } from "../../components"; // Import necessary components
import Footer from "../../components/Footer";
import Header from "../../components/Header";
import StudyabroadRowFour from "./StudyabroadRowFour";
import StudyabroadRowarrowleft from "./StudyabroadRowarrowleft";
import Brand from "../../components/Brand";
import Home1Rowourclinet from "../Home1/Home1Rowourclinet";
import Home1RowSeven from "../Home1/Home1RowSeven";
import FooterContact from "../../components/FooterContact";
import Accordion from 'react-bootstrap/Accordion';
import emailjs from 'emailjs-com'; // Import EmailJS

function SuccessModal({ message, onClose }) {
  return (
    <div className="modal-overlay">
      <div className="modal-container">
        <h3>{message}</h3>
        <button onClick={onClose}>Okay</button>
      </div>
    </div>
  );
}

export default function StudyAbroadPage() {
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [sliderState, setSliderState] = useState(0);
  const [hoveredIndex, setHoveredIndex] = useState(null); // Track the hovered image
  const sliderRef = useRef(null);

  // Form states
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [mobileNumber, setMobileNumber] = useState("");
  const [preferredDestination, setPreferredDestination] = useState("");
  const [studyPlan, setStudyPlan] = useState("");
  const [comments, setComments] = useState("");


  // Scroll to Top Function
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth", // This makes the scrolling behavior smooth
    });
  };

  // EmailJS Form Submit Handler
  const handleFormSubmit = (e) => {
    e.preventDefault();

    const templateParams = {
      firstName,
      lastName,
      email,
      mobileNumber,
      preferredDestination,
      studyPlan,
      comments,
    };

    emailjs
      .send(
        "service_hij3bpr", // Replace with your EmailJS service ID
        "template_s3if2fj", // Replace with your EmailJS template ID
        templateParams,
        "yUT7dkyQWAZoWKCES" // Replace with your EmailJS user ID
      )
      .then(
        (response) => {
          console.log("SUCCESS!", response.status, response.text);
          // Reset form fields
          setFirstName("");
          setLastName("");
          setEmail("");
          setMobileNumber("");
          setPreferredDestination("");
          setStudyPlan("");
          setComments("");
        },
        (error) => {
          console.log("FAILED...", error);
          alert("Failed to send your message. Please try again later.");
        }
      );
      setShowSuccessModal(true);
  };

  const items = [
    {
      defaultImage: "images/img_rectangle_4219_1_1.png",
      hoverImage: "images/hover_img_rectangle_4219_1_1.png",
    },
    {
      defaultImage: "images/img_rectangle_4220_1_1.png",
      hoverImage: "images/hover_img_rectangle_4220_1_1.png",
    },
    {
      defaultImage: "images/img_rectangle_4221_1_1.png",
      hoverImage: "images/hover_img_rectangle_4221_1_1.png",
    },
    {
      defaultImage: "images/img_rectangle_4222_1_1.png",
      hoverImage: "images/hover_img_rectangle_4222_1_1.png",
    },
    {
      defaultImage: "images/img_rectangle_4223_1_1.png",
      hoverImage: "images/hover_img_rectangle_4223_1_1.png",
    },
    // Add more countries if needed
  ];

  return (
    <>
      <Helmet>
        <title>JP Edu Tech</title>
        <meta name="description" content="Web site created using create-react-app" />
      </Helmet>
      <style>{`
      @keyframes slideDown {
              0% {
                transform: translateY(-100%); /* Starts off-screen from the top */
                opacity: 0;
              }
              100% {
                transform: translateY(0); /* Ends in its original position */
                opacity: 1;
              }
            }

            .animate-slideDown {
              animation: slideDown 1.5s ease-in-out forwards; /* Adjust duration and timing function as needed */
            }

            @keyframes slideUp {
              0% {
                transform: translateY(100%); /* Starts off-screen from the top */
                opacity: 0;
              }
              100% {
                transform: translateY(0); /* Ends in its original position */
                opacity: 1;
              }
            }

            .animate-slideUp {
              animation: slideUp 1.5s ease-in-out forwards; /* Adjust duration and timing function as needed */
            }

            @keyframes leftToRight {
              0% {
                transform: translateX(-100%); /* Starts off-screen to the left */
                opacity: 0;
              }
              100% {
                transform: translateX(0); /* Ends in its original position */
                opacity: 1;
              }
            }

            .animate-leftToRight {
              animation: leftToRight 2s ease-in-out forwards; /* Adjust the duration as needed */
            }
               @keyframes RightToleft {
              0% {
                transform: translateX(100%); /* Starts off-screen to the left */
                opacity: 0;
              }
              100% {
                transform: translateX(0); /* Ends in its original position */
                opacity: 1;
              }
            }
             .animate-RightToleft{
             animation: RightToleft 2s ease-in-out backwards;
             } 
          @keyframes zoomInOut {
            0% {
              transform: scale(0.3); /* Normal size */
            }
            100% {
              transform: scale(1.1); /* Zoomed in */
            }
           
          }

          .animate-zoomInOut {
            animation: zoomInOut 3s ease-in-out forwards; /* No infinite, and forwards to keep final state */
          }

          @keyframes spinFadeIn {
          0% {
            transform: rotateY(0);
          }
          50% {
            transform: rotateY(90deg);
          }
          100% {
            transform: rotateY(0);
          }
          }

          .hover-image {
            transition: transform 0.4s ease-in-out, opacity 0.4s ease-in-out;
            
          }

          .hover-image:hover {
            animation: spinFadeIn 1s ease-in-out forwards;
          }

          /* Modal Overlay */
          .modal-overlay {
            position: fixed;
            top: 0;
            left: 0;
            width: 100vw;
            height: 100vh;
            background: rgba(0, 0, 0, 0.5);
            display: flex;
            justify-content: center;
            align-items: center;
            z-index: 1000;
          }

          /* Modal Container */
          .modal-container {
            background: white;
            padding: 20px;
            border-radius: 10px;
            width: 80%;
            max-width: 400px;
            text-align: center;
          }

          .modal-container h3 {
            margin-bottom: 20px;
          }

          .modal-container button {
            padding: 10px 20px;
            background-color: #1b5c82;
            color: white;
            border: none;
            border-radius: 5px;
            cursor: pointer;
          }

          .modal-container button:hover {
            background-color: #0056b3;
          }


      `}
      </style>
      {/*Header*/}
      <Header />

      <div className="banner_inner bg-[url(/public/images/img_group_1010.png)] bg-cover bg-no-repeat relative study_banner">
        <div className="inner_bg bg-[url(/public/images/img_group_981.png)] bg-cover bg-no-repeat">
          <div className="text-center">
            <h1 className="font6 font-bold text-white-a700_01 animate-zoomInOut my-4">Want to Study <br /> Abroad</h1>
            <a href="/enquirepage" className="d-inline-flex mt-8">
              <span className='submitBtn'>
                <span className='cursor-pointer text-capitalize'>Appointment</span>
              </span>
            </a>
          </div>
        </div>
      </div>

      <div className="relative m-none">
        <Img src="images/img_vector_gray_50_04.png" alt="Vector" className="absolute w-full bottom-0" />
      </div>

      <div className="study_destin bg-[url(/public/images/img_group_898.svg)] bg-contain bg-no-repeat bg_bottom bg-gray-50_01">
        <div className="container">
          <div className="text-center">
            <h2 className="font4 font-bold text-black-900_01">Choose your own destination</h2>
            <p className="font-normal text-black-900_02 leading-[30px] tracking-[0.40px] migP mx-auto">
              Begin an exciting academic journey in these varied and welcoming study locations!
            </p>
          </div>
          <div className="study_slider">
            <Slider
              autoPlay
              autoPlayInterval={2000}
              responsive={{
                0: { items: 1 },
                551: { items: 2 },
                1051: { items: 3 },
                1441: { items: 5 },
              }}
              disableDotsControls
              activeIndex={sliderState}
              onSlideChanged={(e) => {
                setSliderState(e?.item);
              }}
              ref={sliderRef}
              items={items.map((item, index) => (
                <React.Fragment key={index}>
                  <div
                    className="flex flex-col px-3.5"
                    onMouseEnter={() => setHoveredIndex(index)} // Set hover state on enter
                    onMouseLeave={() => setHoveredIndex(null)} // Reset hover state on leave
                  >
                    <div className="flex flex-col items-start">
                      <Img
                        src={hoveredIndex === index ? item.hoverImage : item.defaultImage}
                        alt="Study Abroad Destination"
                        className="w-full hover-image rounded-[100px] object-cover lg:h-auto md:mr-0 md:h-auto"
                      />
                    </div>
                  </div>
                </React.Fragment>
              ))}
            />
          </div>
        </div>
      </div>
      
      <StudyabroadRowFour />

      <div className="">
        <div className="container">
          <div className="row">
          <div className="col-lg-6 ps-0 m-none">
            <div className="py-8 px-8 bg-[url(/public/images/img_group_469_1.png)] bg-cover bg-no-repeat">
              <h2 className="font2_5 font-semibold text-white-a700_01 pb-4">
                Empower your Global aspirations with JP EDU TECH
              </h2>
              <div className="form_design mt-4">
              <form onSubmit={handleFormSubmit}>
                <div className="d-lg-flex">
                  <Input
                    size="lg"
                    shape="square"
                    type="text"
                    name="firstName"
                    placeholder="First Name"
                    value={firstName}
                    onChange={(e) => setFirstName(e.target.value)} // Correct
                    style={{ border: "none" }}
                    className="con_label w-100"
                    required
                  />
                  <Input
                    size="lg"
                    shape="square"
                    type="text"
                    name="lastName"
                    placeholder="Last Name"
                    value={lastName}
                    onChange={(e) => setLastName(e.target.value)} // Correct
                    style={{ border: "none" }}
                    className="con_label ms-lg-4 w-100"
                    required
                  />
                </div>

                <Input
                  size="lg"
                  shape="square"
                  type="email"
                  name="email"
                  placeholder="Email address"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)} // Correct
                  style={{ border: "none" }}
                  className="con_label w-100"
                  required
                />

                <Input
                  size="lg"
                  shape="square"
                  type="number"
                  name="mobileNumber"
                  placeholder="Mobile number"
                  value={mobileNumber}
                  onChange={(e) => setMobileNumber(e.target.value)} // Correct
                  style={{ border: "none" }}
                  className="con_label w-100"
                  required
                />

                <Input
                  size="lg"
                  shape="square"
                  name="preferredDestination"
                  placeholder="Your preferred study destination"
                  value={preferredDestination}
                  onChange={(e) => setPreferredDestination(e.target.value)} // Correct
                  style={{ border: "none" }}
                  className="con_label w-100"
                  required
                />
                <Input
                  size="lg"
                  shape="square"
                  name="studyPlan"
                  placeholder="When do you plan to study?"
                  value={studyPlan}
                  onChange={(e) => setStudyPlan(e.target.value)} // Correct
                  style={{ border: "none" }}
                  className="con_label w-100"
                  required
                />

              <textArea
                    size="lg"
                    shape="square"
                    name="comments"
                    placeholder="  Comments..."
                    value={comments}
                    onChange={(e) => setComments(e.target.value)}
                    style={{ border: '1px solid #ccc', height: '100px', width: '100%', padding: '10px' }}
                    className="con_label w-100"
                    required
                  />

  <button type="submit" shape="square" className="submitBtn mt-4 mb-4">
    <span className="cursor-pointer text-capitalize advice">
      Get Experts Advice
    </span>
  </button>
</form>
{showSuccessModal && (
        <SuccessModal
          message="Your message has been sent successfully!"
          onClose={() => setShowSuccessModal(false)}
        />
      )}
              </div>

              {/* Talk to Our Visa Experts Section */}
              <h3 className="font1_5 font-semibold tracking-[0.40px] text-white-a700_01">
                Talk to Our Visa Experts
              </h3>
              <div className="mt-4 d-flex align-items-center">
                <Img src="/images/img_icon_white_a700_01.png" alt="Icon" className="w-40px" />
                <p className="font1_5 font-normal text-white-a700_01 ms-4">
                  <span>+91 8247510882</span>
                  <span className="ms-4">+91 9704428144</span>
                </p>
              </div>
            </div>
            </div>
            <div className="col-lg-6">
              <div className="mob_student">
                <div className="d-flex align-items-start w-100 mt-2">
                  <Img src="images/img_arrow_left.svg" alt="Arrowleft" className="mt-1.5 h-[20px]" />
                  <div className="ms-2">
                    <h3 className="font2 font-semibold color_3A3F50 mb-2">What is a Student Visa?</h3>
                    <p className="font1_5 font-normal leading-[30px] color_000 mb-2">
                    A student Visa is a type of Non-Immigrant Visa. In some countries like Canada, 
                    it is known as a study permit This visa is for prospective students wishing to complete higher education abroad.  
                    </p>
                  </div>
                </div>
                <div className="d-flex align-items-start w-100 mt-2">
                  <Img src="images/img_arrow_left.svg" alt="Arrowleft" className="mt-1.5 h-[20px]" />
                  <div className="ms-2">
                    <h3 className="font2 font-semibold color_3A3F50 mb-2">Achieve Your Dream of Studying Abroad with Jp Edu Tech</h3>
                    <p className="font1_5 font-normal leading-[30px] color_000 mb-2">
                    With the support of Jp Edu Tech, your dream of studying abroad at any top university can become a reality. We assist you every step of the way, from choosing the best institution for your goals to navigating the application and admission process. 
                    Our expertise ensures that you can confidently pursue your education at renowned universities around the globe. 
                    </p>
                  </div>
                </div>
                <div className="d-flex align-items-start w-100 mt-2">
                  <Img src="images/img_arrow_left.svg" alt="Arrowleft" className="mt-1.5 h-[20px]" />
                  <div className="ms-2">
                    <h3 className="font2 font-semibold color_3A3F50 mb-2">How Jp Edu Tech can help you Study Abroad:</h3>
                    <p className="font1 font-normal leading-[30px] color_000 mb-2">
                    <span className="font-semibold">Personalized University Selection:&nbsp;</span>
                    We help you identify the best universities that align with your academic goals and career aspirations.</p>

                    <p className="font1 font-normal leading-[30px] color_000 mb-2">
                    <span className="font-semibold">Expert Application Guidance:&nbsp;</span>
                    Receive step-by-step assistance with your application, ensuring all requirements are met for a successful admission process.</p>
                    
                    <p className="font1 font-normal leading-[30px] color_000 mb-2">
                    <span className="font-semibold">Scholarship and Funding Support:&nbsp;</span>
                    We provide information on scholarships and financial aid opportunities to make studying abroad more affordable.</p>

                    <p className="font1 font-normal leading-[30px] color_000 mb-2">
                    <span className="font-semibold">Visa and Immigration Assistance:&npsp;</span>
                    Our team guides you through the visa application process, helping you navigate immigration requirements with ease.</p>

                    <p className="font1 font-normal leading-[30px] color_000 mb-2">
                    <span className="font-semibold">Pre-Departure Preparation:&npsp;</span>
                    Get ready for your study abroad experience with our comprehensive pre-departure sessions, covering everything from cultural adjustment to accommodation options.</p>
                  </div>
                </div>
                <div className="d-flex align-items-start w-100 mt-2">
                  <Img src="images/img_arrow_left.svg" alt="Arrowleft" className="mt-1.5 h-[20px]" />
                  <div className="ms-2">
                    <h3 className="font2 font-semibold color_3A3F50 mb-2">Related FAQs</h3>
                  </div>
                </div>
                <div className="faq_part">
                  <FaqContent />
                </div>
                <div className="d-lg-none">
                  <button shape="square" className='submitBtn my-2'>
                    <span className='cursor-pointer text-capitalize'>Get Experts Advice</span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <StudyabroadRowarrowleft />
      {/*Brand*/}
      <Brand />
      {/*Testimonial*/}
      <Home1Rowourclinet />
      {/*Contact Form*/}
      <Home1RowSeven />
      <div className="container relative w-100">
        <div className='absolute scroll-index'>
          <span className="scroll-top d-flex align-items-center justify-content-center bg-light_blue-900 cursor-pointer"
            onClick={scrollToTop}>
            <Img src="images/img_vector_white_a700_01_14x24.svg" alt="Vector Eleven" className="h-[14px]" />
          </span>
        </div>
      </div>
      <FooterContact />
      {/*Footer*/}
      <Footer />
    </>
  );
}

export function FaqContent() {
  return (
    <Accordion>
      <Accordion.Item eventKey="0">
        <Accordion.Header>What are the top Masters’s programs abroad Country-Wise?</Accordion.Header>
        <Accordion.Body>
        Computer science, Business administration, Engineering, Healthcare administration, Data science and analytics, 
        International relations, Environmental science and sustainability, Psychology, Public health, Cybersecurity
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="1">
        <Accordion.Header>What are the requirements for a study visa?</Accordion.Header>
        <Accordion.Body>
        Holding Passport , Intermediate to do bachelors and Bachelors to do Masters with English proficiency
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="2">
        <Accordion.Header>What is the best place to study for international students?</Accordion.Header>
        <Accordion.Body>
        According to current trends, the "best" place for international students to study depends on individual needs, but some top choices include the United States, United Kingdom, Canada, Australia.
        </Accordion.Body>
      </Accordion.Item>
    </Accordion>
  );
}

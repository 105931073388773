import React, { useState } from "react";
import { Helmet } from "react-helmet";
import { Img, Slider, Input, TextArea } from "../../components";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import ResourcesRow from "./ResourcesRow";
import ResourcesRowOne from "./ResourcesRowOne";
import Brand from "../../components/Brand";
import Home1Rowourclinet from "../Home1/Home1Rowourclinet";
import Home1RowSeven from "../Home1/Home1RowSeven";
import FooterContact from "../../components/FooterContact";
import emailjs from "emailjs-com";

function SuccessModal({ message, onClose }) {
  return (
    <div className="modal-overlay">
      <div className="modal-container">
        <h3>{message}</h3>
        <button onClick={onClose}>Okay</button>
      </div>
    </div>
  );
}

export default function ResourcesPage() {
  const [sliderState, setSliderState] = React.useState(0);
  const sliderRef = React.useRef(null);
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  // Scroll to Top Function
  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  // Navigate to previous slide
  const goToPreviousSlide = () => {
    if (sliderRef.current) {
      sliderRef.current.slidePrev();  // Try using 'slidePrev' if 'goTo' doesn't exist
    }
  };
  
  const goToNextSlide = () => {
    if (sliderRef.current) {
      sliderRef.current.slideNext();  // Try using 'slideNext'
    }
  };

   // EmailJS state hooks
   const [firstName, setFirstName] = useState("");
   const [lastName, setLastName] = useState("");
   const [email, setEmail] = useState("");
   const [mobileNumber, setMobileNumber] = useState("");
   const [comments, setComments] = useState("");
   const [skills, setSkills] = useState("");
 
   // Predefined receiver email
   const receiverEmail = "jpetcsglobal@gmail.com";
 
   // Function to handle form submission and send email
   const handleFormSubmit = (e) => {
     e.preventDefault();
 
     const templateParams = {
       firstName,
       lastName,
       email,
       mobileNumber,
       comments,
       skills,
       receiver_email: receiverEmail, // Send to the predefined receiver's email
     };
 
     // EmailJS configuration and sending email
     emailjs
       .send(
         "service_hij3bpr", // Replace with your EmailJS service ID
         "template_s3if2fj", // Replace with your EmailJS template ID
         templateParams,
         "yUT7dkyQWAZoWKCES" // Replace with your EmailJS user ID
       )
       .then(
         (response) => {
           console.log("SUCCESS!", response.status, response.text);
           setShowSuccessModal(true);
           // Reset form fields
           setFirstName("");
           setLastName("");
           setEmail("");
           setMobileNumber("");
           setComments("");
           setSkills("");
         },
         (error) => {
           console.log("FAILED...", error);
           alert("Failed to send the message. Please try again.");
         }
       );
   };
  

  return (
    <>
      <Helmet>
        <title>JP Edu Tech</title>
        <meta name="description" content="Web site created using create-react-app" />
      </Helmet>
      <style>
        {`
        @keyframes slideDown {
              0% {
                transform: translateY(-100%); /* Starts off-screen from the top */
                opacity: 0;
              }
              100% {
                transform: translateY(0); /* Ends in its original position */
                opacity: 1;
              }
            }

            .animate-slideDown {
              animation: slideDown 1.5s ease-in-out forwards; /* Adjust duration and timing function as needed */
            }

            @keyframes slideUp {
              0% {
                transform: translateY(100%); /* Starts off-screen from the top */
                opacity: 0;
              }
              100% {
                transform: translateY(0); /* Ends in its original position */
                opacity: 1;
              }
            }

            .animate-slideUp {
              animation: slideUp 1.5s ease-in-out forwards; /* Adjust duration and timing function as needed */
            }

            @keyframes leftToRight {
              0% {
                transform: translateX(-100%); /* Starts off-screen to the left */
                opacity: 0;
              }
              100% {
                transform: translateX(0); /* Ends in its original position */
                opacity: 1;
              }
            }

            .animate-leftToRight {
              animation: leftToRight 2s ease-in-out forwards; /* Adjust the duration as needed */
            }
               @keyframes RightToleft {
              0% {
                transform: translateX(100%); /* Starts off-screen to the left */
                opacity: 0;
              }
              100% {
                transform: translateX(0); /* Ends in its original position */
                opacity: 1;
              }
            }
             .animate-RightToleft{
             animation: RightToleft 2s ease-in-out backwards;
             } 
          @keyframes zoomInOut {
            0% {
              transform: scale(0.3); /* Normal size */
            }
            100% {
              transform: scale(1.1); /* Zoomed in */
            }
           
          }

          .animate-zoomInOut {
            animation: zoomInOut 3s ease-in-out forwards; /* No infinite, and forwards to keep final state */
          }
        @keyframes zoomInOut1 {
          0% {
            transform: scale(0.1); /* Smaller starting size */
          }
          100% {
            transform: scale(1.0); /* Reduced zoom-in size */
          }
        }

        .animate-zoomInOut1 {
          animation: zoomInOut1 3s ease-in-out forwards; /* Adjusted to zoomInOut1 */
        }

                  .modal-overlay {
            position: fixed;
            top: 0;
            left: 0;
            width: 100vw;
            height: 100vh;
            background: rgba(0, 0, 0, 0.5);
            display: flex;
            justify-content: center;
            align-items: center;
            z-index: 1000;
          }

          .modal-container {
            background: white;
            padding: 20px;
            border-radius: 10px;
            width: 80%;
            max-width: 400px;
            text-align: center;
          }

          .modal-container h3 {
            margin-bottom: 20px;
          }

          .modal-container button {
            padding: 10px 20px;
            background-color: #1b5c82;
            color: white;
            border: none;
            border-radius: 5px;
            cursor: pointer;
          }

          .modal-container button:hover {
            background-color: #0056b3;
          }

        `}
      </style>
      {/*Header*/}
      <Header />
      {/* Banner */}
      <div className="banner_inner bg-[url(/public/images/img_group_1465_1.png)] bg-cover bg-no-repeat relative resource_banner">
        <div className="inner_bg">
          <div className="text-center">
            <h1 className="font6 font-bold text-white-a700_01 animate-zoomInOut">
              Resources
            </h1>
          </div>
        </div>
      </div>

      {/* Slider Section */}
      <div className="contact_col black_img mb__70 w-100 res_cols">
        <div className="container">
          <div className="resources_slider">
            <Slider
              autoPlay
              autoPlayInterval={2000}
              ref={sliderRef}
              responsive={{
                0: { items: 1 },
                551: { items: 1 },
                1051: { items: 2 },
                1441: { items: 3 },
              }}
              disableDotsControls
              activeIndex={sliderState}
              onSlideChanged={(e) => setSliderState(e?.item)}
              items={[
                <div className="px-2.5" key="image1">
                  <Img src="images/img_rectangle_4253.png" alt="First Image" className="object-cover" />
                  <div className="my-2">
                    <p className="w-100 font1_5 font-normal leading-[30px] tracking-[0.40px] text-white-a700_01">Welcome to the Jp Edu Tech blog, where we share tips, stories, and insights to support your journey. Explore our posts on everything from studying abroad to finding the perfect career path. We’re here to provide advice and inspiration to help you succeed and grow. Dive into expert insights and learn from</p>
                    <h3 className="font15_5 font-bold text-light_blue-900">Read More..</h3>
                  </div>
                </div>,
                <div className="px-2.5" key="image2">
                  <Img src="images/img_rectangle_4254_2.png" alt="Second Image" className="object-cover" />
                  <div className="my-2">
                    <p className="w-100 font1_5 font-normal leading-[30px] tracking-[0.40px] text-white-a700_01">Welcome to the Jp Edu Tech blog—your one-stop shop for tips, tricks, and the occasional laugh! Join us as we navigate the wild world of studying, working, and living abroad without getting lost. We promise to deliver advice that’s as helpful as it is entertaining. Dive into posts filled with expert insights, real-life</p>
                    <h3 className="font15_5 font-bold text-light_blue-900">Read More..</h3>
                  </div>
                </div>,
                <div className="px-2.5" key="image3">
                  <Img src="images/img_rectangle_4255_2.png" alt="Third Image" className="object-cover" />
                  <div className="my-2">
                    <p className="w-100 font1_5 font-normal leading-[30px] tracking-[0.40px] text-white-a700_01">Welcome to our "Moments" section—where we celebrate the little things that make life special! Join us as we capture and share the moments that define our journeys, both big and small. Whether it’s a breakthrough in your studies, a career milestone, or a simple everyday joy, we’re here to cherish it.</p>
                    <h3 className="font15_5 font-bold text-light_blue-900">Read More..</h3>
                  </div>
                </div>,
              ]}
            />
            <div className="d-flex align-items-center justify-center my-4">
              <button
                className="rotate-180 flex w-full items-center justify-center rounded-full bg-white p-2 arrows"
                onClick={goToPreviousSlide}
              >
                <Img src="images/img_group_2396.svg" alt="Previous" className="h-[18px]" />
              </button>
              <button
                className="rotate-180 flex w-full items-center justify-center rounded-full bg-white p-2 arrows"
                onClick={goToNextSlide}
              >
                <Img src="images/img_group_2397.svg" alt="Next" className="h-[18px]" />
              </button>
            </div>
          </div>

          {/* Ensure Content is Visible Below Slider */}
          <div className="mt-4">
            <ResourcesRow />
          </div>
        </div>
      </div>

      {/* Career Section */}
      <div className="w-100 relative">
        <div className="container mw_1920">
          <div className="row flex-lg-row-reverse">
            <div className="col-lg-6 pe-0 pal-0">
              <div className="bg-light_blue-900 mission_img w-100">
                <Img src="images/img_ellipse_53.png" alt="Image" className="animate-zoomInOut w-90per mx-auto" />
              </div>
            </div>
            <div className="col-lg-6">
              <div className="car_part">
                <div className="d-flex">
                  <h3 className="font3 font-bold text-gray-700_01 pb-2">Career</h3>
                  <Img src="images/img_close_light_blue_900_8x84.svg" alt="Close" className="ms-8" />
                </div>
                <h3 className="font2_25 font-medium text-gray-700_01 pb-2">We are hiring!</h3>
                <p className="font1_5 font-normal leading-[30px] tracking-[0.40px] text-gray-700_01">
                  At <span className="uppercase">JP Edu Tech</span>, we believe in helping you achieve your career goals.
                  Our dedicated team of career counselors offers personalized guidance to navigate today's job market.
                </p>
                <form className="form_design mt-4" onSubmit={handleFormSubmit}>
                  <div className="d-lg-flex">
                    <Input
                      size="xl"
                      shape="square"
                      type="text"
                      name="firstName"
                      placeholder="First name"
                      value={firstName}
                      onChange={(e) => setFirstName(e.target.value)}
                      style={{ border: "none" }}
                      className="con_label w-100"
                      required
                    />
                    <Input
                      size="xl"
                      shape="square"
                      type="text"
                      name="lastName"
                      placeholder="Last name"
                      value={lastName}
                      onChange={(e) => setLastName(e.target.value)}
                      style={{ border: "none" }}
                      className="con_label ms-lg-4 w-100"
                      required
                    />
                  </div>
                  <Input
                    size="xl"
                    shape="square"
                    type="email"
                    name="email"
                    placeholder="Email address"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    style={{ border: "none" }}
                    className="con_label w-100"
                    required
                  />
                  <Input
                    size="xl"
                    shape="square"
                    type="number"
                    name="mobileNumber"
                    placeholder="Mobile number"
                    value={mobileNumber}
                    onChange={(e) => setMobileNumber(e.target.value)}
                    style={{ border: "none" }}
                    className="con_label w-100"
                    required
                  />
                  <Input
                    shape="square"
                    name="skills"
                    placeholder=" Skills"
                    value={skills}
                    onChange={(e) => setSkills(e.target.value)}
                    style={{ border: "none" }}
                    className="con_label w-100"
                    required
                  />
                  <textArea
                    size="xl"
                    shape="square"
                    name="comments"
                    placeholder="Comments..."
                    value={comments}
                    onChange={(e) => setComments(e.target.value)}
                    style={{ border: '1px solid #ccc', height: '100px', width: '100%', padding: '10px' }}
                    className="con_label w-100"
                    required
                  />
                  <button shape="square" className="submitBtn mt-4 mb-4">
                    <span className="cursor-pointer text-uppercase">Send</span>
                  </button>
                </form>
                            {/* Success Modal */}
                {showSuccessModal && (
                  <SuccessModal
                    message="Your message has been sent successfully!"
                    onClose={() => setShowSuccessModal(false)}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Additional Components */}
      <ResourcesRowOne />
      <Brand />
      <Home1Rowourclinet />
      <Home1RowSeven />

      {/* Scroll to Top */}
      <div className="container relative w-100">
        <div className="absolute scroll-index">
          <span className="scroll-top d-flex align-items-center justify-content-center bg-light_blue-900 cursor-pointer" onClick={scrollToTop}>
            <Img src="images/img_vector_white_a700_01_14x24.svg" alt="Vector Eleven" className="h-[14px]" />
          </span>
        </div>
      </div>

      {/* Footer */}
      <FooterContact />
      <Footer />
    </>
  );
}
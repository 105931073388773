import { Img, Input } from "../../components";
import UserProfile2 from "../../components/UserProfile2";
import React, { useState, Suspense } from "react";
import emailjs from "emailjs-com";

function SuccessModal({ message, onClose }) {
  return (
    <div className="modal-overlay">
      <div className="modal-container">
        <h3>{message}</h3>
        <button onClick={onClose}>Okay</button>
      </div>
    </div>
  );
}

const data = [
  { userImage: "images/img_rectangle_4253.png" },
  { userImage: "images/img_rectangle_4254.png" },
  { userImage: "images/img_rectangle_4255.png" },
];

export default function Home1RowSeven() {
  // State to store form values
  const [formValues, setFormValues] = useState({
    name: "",
    email: "",
    mobileNumber: "",
    message: "", // Initialize message state as an empty string
  });
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  // Destructure the form values for easier access
  const { name, email, mobileNumber, message } = formValues;

  // Unified handler for form input changes with additional checks
  const handleInputChange = (e) => {
    if (!e || !e.target) {
      console.error("Event or target is undefined");
      return;
    }

    const { name, value } = e.target;
    if (!name) {
      console.error("Target name attribute is missing");
      return;
    }

    setFormValues((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));
  };

  // Function to handle form submission and send email
  const handleFormSubmit = (e) => {
    e.preventDefault(); // Prevent page refresh

    const templateParams = {
      name,           // Sender's name
      from_name: name, // Map 'from_name' to 'name'
      email,          // Sender's email
      sender_email: email, // Map 'sender_email' to 'email'
      mobileNumber,   // Mobile number
      message,        // User's message
    };
    

    // Replace with your own EmailJS details
    emailjs
      .send(
        "service_hij3bpr", // Replace with your EmailJS service ID
        "template_b5eh1uq", // Replace with your EmailJS template ID
        templateParams,
        "yUT7dkyQWAZoWKCES" // Replace with your EmailJS user ID
      )
      .then(
        (response) => {
          console.log("SUCCESS!", response.status, response.text);
          setShowSuccessModal(true);
          // Clear form fields
          setFormValues({
            name: "",
            email: "",
            mobileNumber: "",
            message: "",
          });
        },
        (error) => {
          console.log("FAILED...", error);
          alert("Failed to send the message. Please try again.");
        }
      );
  };

  return (
    <>
      <style>
        {`
          .modal-overlay {
            position: fixed;
            top: 0;
            left: 0;
            width: 100vw;
            height: 100vh;
            background: rgba(0, 0, 0, 0.5);
            display: flex;
            justify-content: center;
            align-items: center;
            z-index: 1000;
          }

          .modal-container {
            background: white;
            padding: 20px;
            border-radius: 10px;
            width: 80%;
            max-width: 400px;
            text-align: center;
          }

          .modal-container h3 {
            margin-bottom: 20px;
          }

          .modal-container button {
            padding: 10px 20px;
            background-color: #1b5c82;
            color: white;
            border: none;
            border-radius: 5px;
            cursor: pointer;
          }

          .modal-container button:hover {
            background-color: #0056b3;
          }
        `}
      </style>
    <div className='contactus_box w-100 relative'>
      <div className='relative'>
        <Img src="images/img_group_blue_gray_100_02_444x328.png" alt="Image" className="absolute nest m-none" />
      </div>
      <div className="container">
        <div className="row">
          <div className="col-lg-6 ps-0">
            <div className="pt-12 relative m-none">
              <div className="bg-light_blue-900 lefBG"></div>
              <Img src="images/img_rectangle_4396.png" alt="Image" className="con_image relative" />
            </div>
          </div>
          <div className="col-lg-6">
            <div className='pt-8 pl-6 padd0'>
              <Img src='images/img_close_light_blue_900.svg' alt='' className="m-none" />
              <div className='pt-8'>
                <h1 className='font-playfairdisplay font5 font-medium color_3A3F50 animate-RightToleft'>Contact Us</h1>
              </div>
              <p className='py-8 bannerP animate-RightToleft relative'>
                Get in touch with us today! Our expert team will guide you through every step of the migration process.
                Start your journey today with confidence and support from JP Edu Tech!
              </p>
            </div>

            <div className="relative d-lg-none mlr--12">
              <div className="bg-light_blue-900 lefBG"></div>
              <Img src="images/mobile_contact.png" alt="Image" className="con_image relative" />
            </div>

            {/* Contact Form */}
            <form className='pl-6 padd0 con_forms' onSubmit={handleFormSubmit}>
              <Input
                shape="square"
                type="text"
                name="name"
                placeholder="Enter your name.."
                value={name}
                onChange={handleInputChange}
                style={{ border: 'none' }} 
                className="con_label w-100"
                required
              />
              <Input
                shape="square"
                type="email"
                name="email"
                placeholder="Enter your email id.."
                value={email}
                onChange={handleInputChange}
                style={{ border: 'none' }} 
                className="con_label w-100"
                required
              />
              <Input
                shape="square"
                type="number"
                name="mobileNumber"
                placeholder="Enter your mobile number.."
                value={mobileNumber}
                onChange={handleInputChange}
                style={{ border: 'none' }}
                className="con_label w-100"
                required
              />
              {/* Use native HTML textarea for testing */}
              <textarea
                name="message" // Make sure the 'name' attribute is correctly set
                placeholder="Message.."
                value={message}
                onChange={handleInputChange}
                style={{ border: '1px solid #ccc', height: '100px', width: '100%', padding: '10px' }} // Initial height set to 100px
                className="con_label"
                required
              />

              <button type="submit" shape="square" className='submitBtn mb-4'>
                <span className='cursor-pointer text-uppercase'>Send</span>
              </button>
            </form>

            <div className="d-lg-flex animate-RightToleft justify-content-between pl-6 padd0">
              <Suspense fallback={<div>Loading feed...</div>}>
                {data.map((d, index) => (
                  <UserProfile2 {...d} key={"listvectorone" + index} />
                ))}
              </Suspense>
            </div>
          </div>
        </div>
      </div>
            {/* Success Modal */}
      {showSuccessModal && (
        <SuccessModal
          message="Your message has been sent successfully!"
          onClose={() => setShowSuccessModal(false)}
        />
      )}
    </div>
    </>
  );
}

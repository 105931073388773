import React, { useState, Suspense } from "react";
import Slider from "react-slick";
import { Heading, Text } from "../../components";
import UserProfile5 from "../../components/UserProfile5";

const data = [
  { userImage: "images/img_computer_screen.png", hoverImage: "images/hover_image_5.png" },
  { userImage: "images/img_mobile_phone_ta.png", hoverImage: "images/hover_image_6.png" },
  { userImage: "images/img_3d_character_em.png", hoverImage: "images/hover_image_7.png" },
  { userImage: "images/img_man_is_looking.png", hoverImage: "images/hover_image_8.png" },
];

const settings = {
  dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: 2, // Show 2 items per slide on tablets
  slidesToScroll: 1,
  swipeToSlide: true,
  responsive: [
    {
      breakpoint: 768, // Mobile breakpoint
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false,
      },
    },
  ],
};

export default function SoftwareColumnTwo() {
  const [hoverIndex, setHoverIndex] = useState(null);

  return (
    <>
      <div className="container">
        <div className="ml-80per">
          <div className="my-4">
            <h2 className="font2 font-nunitosans font-bold tracking-[-0.61px] text-white-a700_01">Web Designing :</h2>
            <h5 className="font1_5 font-semibold text-light_blue-900 mt-2">Building Intuitive and Engaging Digital Experiences</h5>
            <p className="font1_5 font-normal leading-[30px] tracking-[0.40px] text-white-a700_01 my-3">Web design is essential for creating effective and accessible online platforms that serve educational purposes. At JP Edu Tech, we focus on designing websites that are not only visually appealing but also functional, responsive, and user-centric.</p>
          </div>
          <div className="my-4">
            <h5 className="font1_5 font-semibold text-light_blue-900 mt-2">Our Graphic Design Services Include:</h5>

            {/* Static view for desktop */}
            <div className="d-none d-lg-flex my-4 justify-content-between">
              {data.map((d, index) => (
                <div
                  key={"listwebsitedesi_desktop" + index}
                  onMouseEnter={() => setHoverIndex(index)}
                  onMouseLeave={() => setHoverIndex(null)}
                  className="relative flex flex-col items-center mx-2 sbs"
                >
                  <UserProfile5
                    userImage={hoverIndex === index ? d.hoverImage : d.userImage}
                  />
                </div>
              ))}
            </div>

            {/* Slider for tablet and mobile view */}
            <div className="d-lg-none">
              <Slider {...settings}>
                {data.map((d, index) => (
                  <div key={"listwebsitedesi_mobile" + index}>
                    <div
                      onMouseEnter={() => setHoverIndex(index)}
                      onMouseLeave={() => setHoverIndex(null)}
                      className="relative flex flex-col items-center mx-2 sbs"
                    >
                      <UserProfile5
                        userImage={hoverIndex === index ? d.hoverImage : d.userImage}
                      />
                    </div>
                  </div>
                ))}
              </Slider>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

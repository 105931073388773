import { Img, Text, Button, Heading } from "../../components";
import React from "react";

export default function Home1RowaboutusOne() {
  return (
    <>
    <div className="aboutus_box bg-[url(/public/images/img_group_2380.png)] bg-cover bg-no-repeat w-100 relative">
      <div className="container">
        <div className="bg-white  bg-[url(/public/images/img_group_898.svg)] bg-contain bg-no-repeat relative pos_bottom">
          <div className="aboutus_box_white text-center">
            <Img src="images/img_vector_blue_gray_100_1.png" alt="Vector" className="absolute top-0 about_top m-none" />
            <h1 className='font-playfairdisplay font5 font-medium color_3A3F50'>About Us</h1>
            <h5 className="font-semibold text-cyan-400 fs-5 py-10 abt_sub">Welcome To Our Best Quality Jp Edu Tech</h5>
            <p className="bannerP mw-100 font-medium">
              At JP Edu Tech, we understand that navigating the complexities of visa and immigration processes can be daunting.
              That&#39;s why we&#39;re here—to simplify your journey and ensure your dreams of global mobility become a reality. <br/><br/>

              We specialize in providing comprehensive Visa and Immigration services tailored to meet your specific needs.
              Whether you&#39;re an individual seeking a Students who seek to study overseas, new adventure abroad, a family
              looking to reunite, or a business aiming to expand internationally, our team of experts is committed to guiding
              you through every step of the process. We are here to help you achieve your goals effectively and efficiently.<br/><br />

              <span className="m-none">Our mission is simple: To empower our clients with the knowledge and support they need to achieve their immigration
              goals efficiently and effectively. We prioritize transparency, integrity, and personalized service, ensuring that
              each client receives the attention and care they deserve.</span>
            </p>
            <button shape="square" className='submitBtn mt-8'>
              <span className='cursor-pointer text-capitalize'>Know More</span>
            </button>
          </div>
        </div>
      </div>
      <Img src="images/img_image_9.png" alt="Imagenine" className="absolute about_bottom w-100" />
    </div>
    </>
  );
}

import { Text, Heading } from "../../components";
import React from "react";

export default function AboutusColumnTwo() {
  return (
    <>
      <div className="contact_col black_img top__20 about_cols">
        <div className="container mx-1100">
          <div className="text-center">
            <div className="animate-zoomInOut pb-8">
              <h2 className="font4 font-bold text-light_blue-900">What We Are?</h2>
              <p className="font1_5 my-lg-4 my-3 font-normal leading-[30px] tracking-[0.40px] text-gray-50_03">We specialize in providing comprehensive visa and immigration services tailored to meet your specific needs.
              Whether you&#39;re an individual seeking a Students who seek to study overseas, new adventure abroad, a
              family looking to reunite, or a business aiming to expand internationally, our team of experts is committed
              to guiding you through every step of the process. we are here to help you achieve your goals effectively and
              efficiently.</p>
            </div>
            <div className="animate-zoomInOut">
              <h2 className="font4 my-lg-4 my-3 font-bold text-light_blue-900">About Our CEO</h2>
              <p className="font1_5 font-normal leading-[30px] tracking-[0.40px] text-gray-50_03">John Moses Mekala is the founder of Jp Edu Tech bringing a wealth of international experience and expertise to the forefront of the software
              industry. After completing his higher studies in Melbourne, Australia, where he spent six years honing his
              skills, John returned to India and worked with renowned multinational corporations such as Dell and IBM.
              Driven by a passion for innovation and entrepreneurship, he established JP EDU TECH CONSULTING SERVICES
              PVT LTD to offer cutting-edge software solutions while also dedicating efforts to assist individuals
              seeking opportunities abroad for studies and career advancement. John&#39;s unique blend of global
              experience and industry knowledge ensures that both his clients and students receive exceptional guidance
              and support.</p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

import { Button, Heading, Img } from "../../components";
import React from "react";

export default function Home1RowThree() {
  return (
    <>
    <div className='coaching w-100'>
      <div className="relative m-none">
        <Img src="images/img_group_blue_gray_100_02.png" alt="Image" className="absolute topNest" />
      </div>
      <div className="container">
        <div className="row">
          <div className="col-lg-6">
            <div className='mov_top ml-10 relative'>
              <Img src='images/img_close_light_blue_900.svg' alt='' className="coach_star" />
              <div className='pt-8'>
                <h1 className='font-playfairdisplay font5 font-medium color_3A3F50 animate-leftToRight'> Get The Best Coaching From Us</h1>
              </div>
              <div className="relative d-lg-none mlr--12">
                <div class="w-100 bg-cyan-900_01 rigBG"></div>
                <Img src="images/img_rectangle_4190_1.png" alt="Image Twelve" className="beBG" />
              </div>
              <p className='py-8 bannerP font-semibold animate-leftToRight'>Unlock your potential and reach new heights with our professional coaching services. At Jp Edu Tech, we
              offer personalized coaching designed to guide you through every step of your journey, whether you’re
              preparing for exams, applying for visas, or planning an international move.</p>
              <a href="/englishcoaching" target="_self" className='animate-leftToRight pb-4'>
                <button className='submitBtn'>
                  <span className='cursor-pointer text-capitalize' onClick={() => window.open("/englishcoaching", "_self")}>View More</span>
                </button>
              </a>
            </div>
            <div className="relative m-none">
              <Img src="images/img_group_13.png" alt="Imageteen" className="absolute bottomLine"/>
            </div>
          </div>
          <div className="col-lg-6">
            <div className="relative m-none">
              <div class="w-100 bg-cyan-900_01 rigBG"></div>
              <Img src="images/img_rectangle_4190_1.png" alt="Image Twelve" className="beBG" />
            </div>
          </div>
        </div>
      </div>
      <div className="relative m-none">
        <Img src="images/rect_328.png" alt="Image"  className="absolute bottomNest" />
      </div>
    </div>
    </>
  );
}

import { Img, Text, Heading, Button, TextArea, Input, Slider } from "../../components";
import React from "react";

export default function SoftwareColumn() {
  return (
    <>
      <div className="banner_inner bg-[url('/public/images/img_rectangle_4187_1.png')] bg-cover bg-no-repeat relative software_banner">
        <div className="inner_bg">
          <div className="text-center">
            <h1 className="font6 font-bold text-white-a700_01 animate-zoomInOut">Find a Perfect Solution Through <br /> our Software Consulting.</h1>
            <a href="/enquirepage" className="d-inline-flex mt-8">
              <span className='submitBtn'>
                <span className='cursor-pointer text-capitalize'>Appointment</span>
              </span>
            </a>
          </div>
        </div>
      </div>
    </>
  );
}

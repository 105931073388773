import React, { useState, useEffect ,useRef } from "react";
import emailjs from "emailjs-com";

export default function ChatModal({ isOpen, onClose }) {
  const [step, setStep] = useState(0);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [messages, setMessages] = useState([]);
  const [isSending, setIsSending] = useState(false);
  
  const receiverEmail = "jpetcsglobal@gmail.com";
  const messagesEndRef = useRef(null);
  const chatContainerRef = useRef(null);
  const questions = [
    "Hi there, i have a question about immigration. As well as share your name?",
    "Nice to meet you! Could you provide your email address?",
    "Thank you. What's your question or message for us?",
  ];

  useEffect(() => {
    setMessages((prevMessages) => [
      ...prevMessages,
      { type: "bot", text: questions[step] },
    ]);
  }, [step]);


  const getCurrentTime = () => {
    const now = new Date();
    return now.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
  };

  const handleSendInfo = () => {
    setIsSending(true);

    const templateParams = {
      from_name: name,
      sender_email: email,
      message: message,
      receiver_email: receiverEmail,
    };

    emailjs
      .send(
        "service_hij3bpr",
        "template_b5eh1uq",
        templateParams,
        "yUT7dkyQWAZoWKCES"
      )
      .then(
        (response) => {
          console.log("SUCCESS!", response.status, response.text);
          setMessages((prevMessages) => [
            ...prevMessages,
            { type: "bot", text: "Thank you for reaching out! Have a great day! 😊" },
          ]);
          setStep(3);
        },
        (error) => {
          console.log("FAILED...", error);
          alert("Failed to send the message, please try again.");
        }
      )
      .finally(() => setIsSending(false));
  };

  const addMessage = (text, sender = "user") => {
    setMessages((prevMessages) => [...prevMessages, { type: sender, text, time: getCurrentTime() }]);
  };

  if (!isOpen) return null;

  return (
    <>
      <style>
        {`
          .chat-modal-backdrop {
            position: absolute;
            top: 10px;
            right: 30px;
            left: 0;
            width: 100%;
            height: 100%;
            background-color: rgba(0, 0, 0, 0);
            display: flex;
            align-items: center;
            justify-content: top;
            z-index: 1050;
          }

          .chat-modal-container {
            position: absolute;
            top: -330px;
            right: 10px;
            width: 400px;
            background: white;
            border-radius: 10px;
            box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
            max-height: 100vh;
            display: flex;
            flex-direction: column;
            overflow: hidden;
          }

          /* Media query for mobile devices */
          @media (max-width: 768px) {
            .chat-modal-container {
              width: 250px; /* Set the width to 250px for mobile devices */
            }
          }


          .chat-modal-header {
            display: flex;
            align-items: center;
            justify-content: space-between;
            background: url('${process.env.PUBLIC_URL}/images/chatbg.png') no-repeat center center;
            background-size: cover;
            padding: 15px;
            position: relative;
          }

          .chat-header-circle {
            width: 50px;
            height: 50px;
            background-color: #1E5878;
            border-radius: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 1.25rem;
            font-weight: bold;
            color: white;
          }

          .chat-modal-title {
            flex: 1;
            text-align: left;
            font-size: 1.25rem;
            font-weight: bold;
            color: white;
          }

          .chat-close-btn {
            width: 30px;
            height: 30px;
            background-color: transparent;
            border: none;
            border-radius: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
            position: absolute;
            top: 10px;
            right: 10px;
            padding: 0;
          }

          .chat-close-btn img {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }

          .chat-status {
            display: flex;
            align-items: center;
            background-color: black;
            padding: 10px 15px;
            font-size: 0.9rem;
            color: #fff;
          }

          .status-indicator {
            width: 10px;
            height: 10px;
            background-color: green;
            border-radius: 50%;
            margin-right: 8px;
            display: inline-block;
          }
          .chat-messages {
            display: flex;
            flex-direction: column;
            gap: 10px;
            padding: 10px;
            background: #f9f9f9;
            height: 100px;
            overflow-y: auto;
          }

          /* Shared Styles for All Messages */
          .chat-message {
            max-width: 70%;
            padding: 10px 15px;
            border-radius: 10px;
            word-wrap: break-word;
            position: relative;
            display: flex;
            flex-direction: column;
            font-size: 0.6rem;
          }

          /* User Sent Message - Aligned Left */
          .chat-message.user {
            align-self: flex-start;
            background-color: #1e6580;
            color: white;
            border-top-left-radius: 0;
            text-align: left;
          }

          /* Bot/Received Message - Aligned Right */
          .chat-message.bot {
            align-self: flex-end;
            background-color: #e9eef4;
            color: #333;
            border-top-right-radius: 0;
            text-align: left;
          }

          .chat-input-container {
            display: flex;
            align-items: center; /* Align items vertically in the center */
            padding: 8px 12px; /* Adjusted padding for spacing */
            background-color: #f1f8fc;
            gap: 10px; /* Space between elements */
            width: 100%; /* Ensure it spans the full container width */
            box-sizing: border-box; /* Include padding in element's total width */
          }


          .chat-input {
            flex-grow: 1;
            border: 0.1px solid #ccc;
            padding: 10px;
            border-radius: 5px 0 0 5px;
          }

          .chat-send-btn {

            color: white;
            cursor: pointer;
          }

          .chat-send-btn[disabled] {
            background: #fff;
            cursor: not-allowed;
          }

          /* Timestamp Styling */
          .timestamp {
            font-size: 0.75rem;
            color: #999;
            text-align: right;
            margin-top: 5px;
          }

        .chat-icons {
          display: flex;
          flex-direction: row; /* Icons in a row */
          align-items: center;
          justify-content: center; /* Center them horizontally */
          gap: 10px; /* Add space between icons */
        }

        `}
      </style>

      <div className="chat-modal-backdrop">
        <div className="mb-96 chat-modal-container">
          <div className="chat-modal-header">
            <div className="chat-header-circle">JET</div>
            <h2 className="chat-modal-title">
              Chat with <br /> JP EDU TECH
            </h2>
            <button onClick={onClose} className="chat-close-btn">
              <img src={`${process.env.PUBLIC_URL}/images/arrow.png`} alt="Close" />
            </button>
          </div>

          <div className="chat-status">
            <span className="status-indicator"></span>
            We reply immediately...
          </div>

          <div className="chat-messages">
      {messages.map((msg, index) => (
        <div key={index} className={`chat-message ${msg.type}`}>
          <p>{msg.text}</p>
          <span className="timestamp">{msg.time}</span>
        </div>
      ))}
      {/* Add a ref to this div to scroll into view */}
      <div ref={messagesEndRef}></div>
    </div>

          {step < 3 && (
            <div className="chat-input-container">
            <input
              type="text"
              className="chat-input"
              placeholder={
                step === 0
                  ? "Enter your name..."
                  : step === 1
                  ? "Enter your email..."
                  : "Enter your message..."
              }
              value={step === 0 ? name : step === 1 ? email : message}
              onChange={(e) => {
                if (step === 0) setName(e.target.value);
                else if (step === 1) setEmail(e.target.value);
                else setMessage(e.target.value);
              }}
              onKeyDown={(e) => {
                if (e.key === "Enter" && message.trim() !== "") {
                  addMessage(`My question is: ${message}`, "user");
                  setMessage(""); // Clear input field after sending
                }
              }}
            />
            <div className="chat-icons">
                <img
                  src={`${process.env.PUBLIC_URL}/images/Vector.png`}
                  alt="Attach"
                  className="chat-icon"
                />
                <img
                  src={`${process.env.PUBLIC_URL}/images/smile.png`}
                  alt="Emoji"
                  className="chat-icon"
                />
              </div>
            <button
              className="chat-send-btn"
              onClick={() => {
                if (step === 0) addMessage(`My name is ${name}.`);
                else if (step === 1) addMessage(`My email is ${email}.`);
                else if (step === 2) {
                  addMessage(`My question is: ${message}.`);
                  handleSendInfo();
                  setMessage(""); // Clear input field after sending
                }
                setStep((prev) => prev + 1);
              }}
              disabled={
                (step === 0 && !name) ||
                (step === 1 && !email) ||
                (step === 2 && !message)
              }
            >
              <img src={`${process.env.PUBLIC_URL}/images/Group 105.png`} alt="Send" />
            </button>
          </div>
          )}
        </div>
      </div>
    </>
  );
}

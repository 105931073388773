import { Img, Button, Text, Heading } from "../../components";
import React from "react";

export default function StudyabroadRowarrowleft() {
  return (
    <>
    <div className="why_migrate w-100 bg-[url(/public/images/img_group_998.png)] bg-contain bg_F8FEFD bg_bottom bg-no-repeat">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-lg-7">
            <div className="w-100">
              <h6 className="font3 my-lg-4  my-2 font-bold tracking-[-0.91px] text-light_blue-900">Your Global Education, Funded</h6>
              <div className="w-100">
                <p className="font15_5 font-normal leading-[36px] tracking-[0.48px] text-gray-800 w-90per">Realize your dream of studying abroad with our flexible study loan options. We offer competitive
                interest rates, convenient repayment terms, and minimal paperwork. Get Pre-Approved quickly and
                focus on your studies. From tuition to living expenses, we&#39;ve got you covered. Start your
                global adventure today!</p>
                <div className="d-flex align-items-start w-100 mt-lg-4 mt-2">
                  <Img src="images/img_arrow_left.svg" alt="Arrowleft" className="mt-1.5 h-[22px] Arrowleft" />
                  <p className="font15_5 font-normal leading-[30px] tracking-[0.48px] text-gray-800 ms-lg-4 ms-2">
                  Competitive Interest Rates
                  </p>
                </div>
                <div className="d-flex align-items-start w-100 mt-lg-4 mt-2">
                  <Img src="images/img_arrow_left.svg" alt="Arrowleft" className="mt-1.5 h-[22px] Arrowleft" />
                  <p className="font15_5 font-normal leading-[30px] tracking-[0.48px] text-gray-800 ms-lg-4 ms-2">
                  Flexible Repayment Option
                  </p>
                </div>
                <div className="d-flex align-items-start w-100 mt-lg-4 mt-2">
                  <Img src="images/img_arrow_left.svg" alt="Arrowleft" className="mt-1.5 h-[22px] Arrowleft" />
                  <p className="font15_5 font-normal leading-[30px] tracking-[0.48px] text-gray-800 ms-lg-4 ms-2">
                  Minimal Documentation
                  </p>
                </div>
                <div className="d-flex align-items-start w-100 mt-lg-4 mt-2">
                  <Img src="images/img_arrow_left.svg" alt="Arrowleft" className="mt-1.5 h-[22px] Arrowleft" />
                  <p className="font15_5 font-normal leading-[30px] tracking-[0.48px] text-gray-800 ms-lg-4 ms-2">
                  Fast Approval Process
                  </p>
                </div>
                <div className="d-flex align-items-start w-100 mt-lg-4 mt-2">
                  <Img src="images/img_arrow_left.svg" alt="Arrowleft" className="mt-1.5 h-[22px] Arrowleft" />
                  <p className="font15_5 font-normal leading-[30px] tracking-[0.48px] text-gray-800 ms-lg-4 ms-2">
                  Comprehensive Coverage
                  </p>
                </div>
                <a href="/enquirepage" target="_self" className="my-lg-5 my-3">
                  <button shape="square" className='submitBtn'>
                    <span className='cursor-pointer text-capitalize'>Know More</span>
                  </button>
                </a>
              </div>
            </div>
          </div>
          <div className="col-lg-5 pe-0">
            <div className="relative  m-none">
              <Img
                src="images/img_rectangle_4381.png"
                alt="Image"
                className="rounded-[10px]"
              />
              <Img
                src="images/img_graduation_cap.png"
                alt="Graduationcap"
                className="absolute left__8 bottom-0 z-40"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    </>
  );
}

import { Img, Heading, Text } from "./..";
import React from "react";

export default function Footer({ ...props }) {
  return (
    <>
    <footer className="bg-dark w-100 text-white bg-[url(/public/images/img_group_943.png)] bg-cover bg-no-repeat">
      <div className="footer_bg pb-4">
        <div className="container">
          <div className="row align-items-center">
          <div className="col-xl-2 col-lg-2 col-5 d-flex justify-content-center align-items-center">
            <Img 
              src="images/img_logos_white_1.png" 
              alt="Logoswhiteone" 
              className="w-140px" 
            />
          </div>

            <div className="col-xl-7 col-lg-7 col-7">
              <ul className="footer-link d-flex flex-lg-row flex-column align-items-lg-start align-items-start">
                <li className="mb-2">
                  <a href="/home" target="_self" rel="noreferrer">Home</a>
                </li>
                <li className="mb-2">
                  <a href="/studyabroad" target="_self">Study Abroad</a>
                </li>
                <li className="mb-2">
                  <a href="/software" target="_self">Software Consulting</a>
                </li>
                <li className="mb-2">
                  <a href="/aboutus" target="_self">About Us</a>
                </li>
                <li className="mb-2">
                  <a href="/contactus" target="_self">Contact Us</a>
                </li>
              </ul>
            </div>

            <div className="col-xl-3 col-lg-3 col-5 relative">
              <div className="social_icon d-flex justify-content-lg-end justify-content-start">
                <a href="https://www.facebook.com/share/XL5X5UrAG4VuFYuy/?mibextid=qi2Omg" target="_blank">
                  <Img src="images/img_close_white_a700_01.svg" alt="Close" className="" />
                </a>
                <a href="https://wa.me/message/2I52EUSWYTQBG1" target="_blank">
                  <Img src="images/img_volume_white_a700_01.svg" alt="Volume" className=""  />
                </a>
                <a href="https://www.instagram.com/jpetcs?igsh=MW5xNjF3aDN5dTE3bw==" target="_blank">
                  <Img src="images/img_info_white_a700_01.svg" alt="Info" className="" />
                </a>
                <a href="https://x.com/jpedutech" target="_blank" >
                  <Img src="images/img_trash.svg" alt="Trash" className=""  />
                </a>
              </div>
            </div>
          </div>
        </div>
        <div className="container py-4  m-none">
          <div className="border-bottom"></div>
        </div>
        <div className="container fs-6 mob-size font-semibold">
          <div className="d-flex">
            <div className="">
              <p>@2024 JP Edu Tech Consulting Serivces Pvt Ltd. All rights reserved. </p>
            </div>
            <div className="ms-auto">
              <div className="d-flex justify-content-end">
                <a href="#" className="pr-20px">Terms</a>
                <a href="#">Privacy Policy</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  </>
  );
}

import { Heading, Text } from "../../components";
import ContactDetails from "../../components/ContactDetails";
import React from "react";

// Updated Contact Data
const data = [
  {
    callIcon: "images/img_call.svg",
    contactImage: "images/img_rectangle_4439_1.png",
  },
  {
    callIcon: "images/img_lock.svg",
    contactImage: "images/img_rectangle_4431_1.png",
  },
  {
    callIcon: "images/img_linkedin_light_blue_900.svg",
    contactImage: "images/img_rectangle_4433_1.png",
  },
];

export default function ContactusColumn() {
  return (
    <div className="contact_col bg-black mb-16 w-100">
      <div className="container mw_1400">
        <div className="text-center">
          <Heading className="font3 font-semibold text-white animate-zoomInOut">
            Contact Details
          </Heading>
          <Text className="text-white animate-zoomInOut mt-4">
            Get in touch with us today! Our expert team will guide you through every step of the migration process.
            Start your journey today with confidence and support from JP Edu Tech!
          </Text>
        </div>
        <div className="mt-16 animate-zoomInOut">
          <div className="row">
            {data.map((d, index) => (
              <ContactDetails {...d} key={`listcallone_${index}`} />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}

import { Text, Img, Button, Heading } from "../../components";
import React from "react";

export default function ResourcesRow() {
  return (
    <>
      <style>
        {`
        .image-container {
          position: relative;
        }

        .image-container img {
          width: 100%;
          height: auto;
          display: block;
        }

        .overlay-text {
          position: absolute;
          bottom: 0;
          left: 0;
          right: 0;
          background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.6) 100%);
          padding: 15px;
          color: white;
          display: flex;
          flex-direction: column;
          justify-content: flex-end;
          max-height: 100%; /* Ensures overlay doesn't exceed image height */
        }

        .overlay-text h5 {
          font-size: 1.1rem;
          margin-bottom: 10px;
          line-height: 1.2; /* Ensures better readability */
          overflow: hidden; /* Prevents overflow if the text is too long */
          text-overflow: ellipsis; /* Adds ellipsis if text overflows */
          display: -webkit-box;
          -webkit-line-clamp: 3; /* Limits the number of lines to 3 */
          -webkit-box-orient: vertical;
        }

        .overlay-text a {
          color: #1e90ff;
        }
        `}
      </style>
      <div className="news_section">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-6">
              <div className="d-flex">
                <h3 className="font3 font-bold text-white-a700_01">News</h3>
                <Img src="images/img_close_light_blue_900_8x84.svg" alt="Close" className="ms-8" />
              </div>
              <p className="font1_5 font-normal leading-[33px] tracking-[0.40px] text-white-a700_01 py-6 w-90per">
                Stay updated with the latest news and announcements from Jp Edu Tech. We bring you valuable insights on migration, education, and career opportunities. Our news section keeps you informed about new developments and important changes. From helpful tips to exciting updates, find everything you need to stay ahead. Check back often to ensure you don’t miss out on the information that matters to you.
              </p>
              <button shape="square" className='submitBtn my-lg-4'>
                <span className='cursor-pointer text-capitalize'>Know More</span>
              </button>
            </div>
            <div className="col-lg-6">
              <div className="d-lg-flex justify-content-center mt-xxx">
                {/* First Column of Images */}
                <div className="me-2">
                  {/* Image with Overlay Text */}
                  <div className="image-container position-relative mb-2">
                    <Img src="images/img_rectangle_4403_1.png" alt="Image" className="w-100 img-fluid" />
                    <div className="overlay-text position-absolute">
                      <h5>Recent policy changes in the UK, Canada, and Australia are affecting international student mobility. Students are exploring alternative study destinations.</h5>
                      <a href="#" className="text-blue-500">Read More..</a>
                    </div>
                  </div>
                  <div className="image-container position-relative">
                    <Img src="images/img_rectangle_4188_2.png" alt="Image" className="w-100 img-fluid" />
                    <div className="overlay-text position-absolute">
                      <h5>Government initiatives to promote digital transformation and software adoption.</h5>
                      <a href="#" className="text-blue-500">Read More..</a>
                    </div>
                  </div>
                </div>
                {/* Second Large Image */}
                <div className="image-container position-relative">
                  <Img src="images/img_rectangle_4403_606x320_1.png" alt="Image" className="w-100 img-fluid" />
                  <div className="overlay-text position-absolute">
                    <h5>Countries are offering extended work permits to attract international students and boost their economies.</h5>
                    <a href="#" className="text-blue-500">Read More..</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

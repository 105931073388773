import React from "react";
import { createRoot } from "react-dom/client";
import App from "./App";
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap/dist/js/bootstrap.js';
import 'bootstrap-icons/font/bootstrap-icons.css';
import 'swiper/css';
import "./styles/tailwind.css";
import "./styles/index.css";
import "./styles/font.css";
import './styles/main.scss';
import './styles/responsive.scss';

const container = document.getElementById("root");
const root = createRoot(container);

root.render(<App />);

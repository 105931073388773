import React, { useState } from "react";
import { Heading, Img, Slider } from "../../components";

// Data array for default and hover images
const data = [
  { defaultImage: "images/img_rectangle_4219_2.png", hoverImage: "images/hover_img_rectangle_4219_1_2.png" },
  { defaultImage: "images/img_rectangle_4220_2.png", hoverImage: "images/hover_img_rectangle_4220_1_2.png" },
  { defaultImage: "images/img_rectangle_4221_2.png", hoverImage: "images/hover_img_rectangle_4221_1_2.png" },
  { defaultImage: "images/img_rectangle_4222_2.png", hoverImage: "images/hover_img_rectangle_4222_1_2.png" },
  { defaultImage: "images/img_rectangle_4223_2.png", hoverImage: "images/hover_img_rectangle_4223_1_2.png" },
];



export default function Home1ColumnFour() {
  const [sliderState, setSliderState] = React.useState(0);
  const [hoveredIndex, setHoveredIndex] = useState(null); // Track the hovered image
  const sliderRef = React.useRef(null);
  const items = [
    { defaultImage: "images/img_rectangle_4219_2.png", hoverImage: "images/hover_img_rectangle_4219_1_2.png" },
    { defaultImage: "images/img_rectangle_4220_2.png", hoverImage: "images/hover_img_rectangle_4220_1_2.png" },
    { defaultImage: "images/img_rectangle_4221_2.png", hoverImage: "images/hover_img_rectangle_4221_1_2.png" },
    { defaultImage: "images/img_rectangle_4222_2.png", hoverImage: "images/hover_img_rectangle_4222_1_2.png" },
    { defaultImage: "images/img_rectangle_4223_2.png", hoverImage: "images/hover_img_rectangle_4223_1_2.png" },
  ]
  return (
    <>
      <style>{`
        /* Define the spin animation */
        @keyframes twistSpin {
          0% {
            transform: rotateY(0);
          }
          50% {
            transform: rotateY(90deg);
          }
          100% {
            transform: rotateY(0);
          }
        }

        .twist-animation {
          animation: twistSpin 1s ease-in-out forwards;
        }
      `}
      </style>
      <div className="migrate w-100 relative bg-[url(/public/images/img_mask_group_984x1918.png)] bg-cover bg-no-repeat">
        <div className='container'>
          <div className='text-center'>
            <p className='font-playfairdisplay font10 font-medium text-white-a700_01 text-uppercase'>Migrate</p>
            <p className='font-semibold text-white-a700_01 leading-[30px] tracking-[0.40px] migP mx-auto'>We're here to help! Our expert team will guide you through every step of the Migration process. 
              Start your journey today with confidence and support from JP Edu Tech!</p>
          </div>
          {/*  
          <div className="d-flex justify-content-between mh_600">
            {data.map((item, index) => (
              <div
                key={index}
                onMouseEnter={() => setHoveredIndex(index)} // Set hoveredIndex on hover
                onMouseLeave={() => setHoveredIndex(null)} // Reset hoveredIndex on mouse leave
                className={`transition-transform duration-300 transform ${
                  hoveredIndex === index ? "twist-animation" : ""
                }`}
              >
                <img
                  src={hoveredIndex === index ? item.hoverImage : item.defaultImage} // Show hoverImage if hovered
                  alt={`Image ${index + 1}`}
                  className="h-[392px] object-cover"
                />
              </div>
            ))}
          </div> */}
          <div className="study_slider mh_600">
            <Slider
              autoPlay
              autoPlayInterval={2000}
              responsive={{
                0: { items: 1 },
                551: { items: 2 },
                1051: { items: 3 },
                1441: { items: 5 },
              }}
              disableDotsControls
              activeIndex={sliderState}
              onSlideChanged={(e) => {
                setSliderState(e?.item);
              }}
              ref={sliderRef}
              items={items.map((item, index) => (
                <React.Fragment key={index}>
                  <div
                    className="flex flex-col px-3.5"
                    onMouseEnter={() => setHoveredIndex(index)} // Set hover state on enter
                    onMouseLeave={() => setHoveredIndex(null)} // Reset hover state on leave
                  >
                    <div className="flex flex-col items-start">
                      <Img
                        src={hoveredIndex === index ? item.hoverImage : item.defaultImage}
                        alt="Study Abroad Destination"
                        className="h-[360px]"
                      />
                    </div>
                  </div>
                </React.Fragment>
              ))}
            />
          </div>
        </div>
      </div>

    </>
  );
}


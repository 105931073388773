import { Text, Img } from "./..";
import React from "react";

export default function StudentVisaProfile({
  studentImage = "images/img_graduation_1.png",
  studentVisaText = "Student Visa",
  ...props
}) {
  return (
    <div {...props} className={`${props.className} col-6`}>
      <div className="d-flex w-full align-items-center border-light_blue-900 border-l border-solid bg-white-a700_01 px-4 py-3 box_col_shade">
        <div className="d-flex align-items-center justify-content-center rounded-full bg-light_blue-900 p-1.5 mob_migs">
          <Img src={studentImage} alt="Visa" className="h-[40px]" />
        </div>
        <span className="font1_5 font-semibold tracking-[-0.38px] text-black-900_02 ms-lg-4 ms-2">{studentVisaText}</span>
      </div>
    </div>
  );
}
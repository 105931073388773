import React from "react";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";

const Home = () => {
  const pages = [
    { path: "/home", title: "Home", description: "Welcome to JP Edu Tech's Home Page." },
    { path: "/studyabroad", title: "Study Abroad", description: "Explore study abroad opportunities at JP Edu Tech." },
    { path: "/software", title: "Software Consulting", description: "Discover software consulting solutions for your business." },
    { path: "/migrate", title: "Migrate", description: "Learn how to migrate with JP Edu Tech's expert guidance." },
    { path: "/englishcoaching", title: "English Coaching", description: "Get English language coaching with JP Edu Tech." },
    { path: "/aboutus", title: "About Us", description: "Know more about JP Edu Tech and our journey." },
    { path: "/resources", title: "Resources", description: "Access educational resources from JP Edu Tech." },
    { path: "/contactus", title: "Contact Us", description: "Get in touch with JP Edu Tech for more information." },
    { path: "/enquirepage", title: "Enquire Page", description: "Submit your enquiries to JP Edu Tech." },
  ];

  return (
    <>
      <Helmet>
        <title>JP Edu Tech - Home</title>
        <meta
          name="description"
          content="Explore JP Edu Tech, your trusted partner for Study Abroad, Software Consulting, Migration services, and more!"
        />
      </Helmet>

      <div
        style={{ height: "100vh", padding: "20px", backgroundColor: "#232323", color: "white" }}
      >
        <h1 style={{ fontSize: "48px", marginBottom: "20px" }}>Homepage</h1>
        <ul style={{ listStyle: "none", padding: "0" }}>
          {pages.map((page, index) => (
            <li key={index} style={{ marginBottom: "10px" }}>
              <Helmet>
                <title>{page.title}</title>
                <meta name="description" content={page.description} />
              </Helmet>
              <Link to={page.path} style={{ color: "#87CEFA", textDecoration: "none" }}>
                {page.title}
              </Link>
            </li>
          ))}
        </ul>
      </div>
    </>
  );
};

export default Home;

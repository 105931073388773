import React from "react";
import { useRoutes, Navigate } from "react-router-dom"; // Import Navigate for redirection
import Home from "pages/Home";
import NotFound from "pages/NotFound";
import Home1 from "pages/Home1";
import StudyAbroad from "pages/StudyAbroad";
import Software from "pages/Software";
import Migrate from "pages/Migrate";
import EnglishCoaching from "pages/EnglishCoaching";
import Aboutus from "pages/Aboutus";
import HomeOne from "pages/HomeOne";
import Resources from "pages/Resources";
import ContactUs from "pages/ContactUs";
import Enquirepage from "pages/Enquirepage";

const ProjectRoutes = () => {
  let element = useRoutes([
    // Redirect root URL to Home1 page
    { path: "/", element: <Navigate to="/home" /> },

    // Define individual routes
    { path: "/home", element: <Home1 /> },
    { path: "/homeone", element: <HomeOne /> },
    { path: "/studyabroad", element: <StudyAbroad /> },
    { path: "/software", element: <Software /> },
    { path: "/migrate", element: <Migrate /> },
    { path: "/englishcoaching", element: <EnglishCoaching /> },
    { path: "/aboutus", element: <Aboutus /> },
    { path: "/resources", element: <Resources /> },
    { path: "/contactus", element: <ContactUs /> },
    { path: "/enquirepage", element: <Enquirepage /> },

    // Catch-all route for 404 pages
    { path: "*", element: <NotFound /> },
  ]);

  return element;
};

export default ProjectRoutes;
